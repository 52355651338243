<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn
          v-if="!addDocument"
          text
          class="blue--text text--lighten-2 font-weight-bold mt-4"
          @click="showAddDocument()"
        >
          Add Document
        </v-btn>
      </v-col>
      <v-col v-if="!addDocument">
        <v-data-table
          id="tblDocument"
          :headers="headers"
          :items="documents"
          class="elevation-1"
          calculate-widths
          disable-pagination
          hide-default-footer
          hide-slider
          fixed-header
        >
          <template v-slot:item.file="{ item }">
            <a :href="item.file" target="_blank" style="text-decoration: none">
              <v-icon class="pl-3 pr-1 gray--text text--lighten-2" medium>
                mdi-file-document
              </v-icon>
            </a>
          </template>
          <template v-slot:item.modifyDetail="{ item }">
            <p
              v-if="item.modifyDetail !== null"
              class="mb-0"
              style="width: 70%; float: left"
            >
              <span class="font-weight-bold">{{
                format_date(item.modifyDetail.updatedAt)
              }}</span>
              <br />
              <span class="subtitle">{{
                item.modifyDetail.uploadedByDisplay
              }}</span>
            </p>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" class="float-right" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn text small @click="editDocument(item)"> Edit </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    class="red--text"
                    text
                    small
                    @click="deleteDocument(item)"
                  >
                    Delete
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col v-else>
        <v-card>
          <v-progress-linear
            v-if="uploadProgress > 0"
            v-model="uploadProgress"
            height="25"
          >
            <strong>{{ Math.ceil(uploadProgress) }}%</strong>
          </v-progress-linear>
          <v-container>
            <v-form ref="carrier-document-upload">
              <v-col>
                <v-col cols="12" offset-md="4" md="4">
                  <v-text-field
                    v-model="documentName"
                    type="text"
                    label="Name"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" offset-md="4" md="4">
                  <v-select
                    v-model="documentType"
                    :items="documentTypes"
                    label="Document Types"
                    item-text="name"
                    item-value="id"
                    dense
                    return-object
                    :rules="[rules.required]"
                    style="text-transform: none"
                  />
                </v-col>
                <v-col cols="12" offset-md="4" md="4">
                  <v-file-input
                    v-model="fileInput"
                    show-size
                    counter
                    :rules="documentId ? [] : [rules.required]"
                    label="File input"
                    accept="image/*,.pdf,.doc,.docx,.csv"
                  />
                  <a v-if="hasEdit" :href="fileURL" target="_blank"
                    >View File</a
                  >
                </v-col>
              </v-col>
            </v-form>
            <v-row>
              <v-col cols="12">
                <v-btn
                  text
                  class="blue white--text font-weight-bold mt-4 offset-md-4"
                  @click="uploadDocument()"
                >
                  {{ hasEdit === true ? "Update" : "Upload" }}
                </v-btn>
                <v-btn
                  text
                  class="grey white--text font-weight-bold mt-4 ml-2"
                  @click="cancelUpload()"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-text> Please confirm document deletion </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="grey darken-1" text small @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="orange darken-1" text small @click="removeRecord()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
import moment from "moment"
import validation from "@/utils/form-validation"
export default {
  name: "Documents",
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      hasEdit: false,
      index: -1,
      addDocument: false,
      documentName: "",
      fileURL: "",
      fileInput: null,
      documentId: null,
      documentType: null,
      documentTypes: [
        {
          id: 1,
          name: "General",
        },
        {
          id: 2,
          name: "Insurance",
        },
        {
          id: 4,
          name: "MC Certificate",
        },
        {
          id: 5,
          name: "W9",
        },
      ],
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "documentTypeDisplay" },
        { text: "Document", value: "file" },
        { text: "Modified", value: "modifyDetail" },
      ],
      documents: [
        {
          id: 0,
          createdAt: null,
          modifyDetail: {
            updatedAt: null,
            uploadedByDisplay: null,
          },
          file: null,
          fileName: null,
          name: null,
          documentType: null,
          documentTypeDisplay: null,
          version: "1.0",
        },
      ],
      rules: validation.rules,
    }
  },
  computed: {
    ...mapGetters("carrier", {
      carrierDocuments: "carrierDocuments",
      carrierDocument: "carrierDocument",
      uploadProgress: "uploadProgress",
    }),
  },
  watch: {
    carrierDocuments() {
      this.documents = this.carrierDocuments.results
    },
    carrierDocument() {
      this.documentName = this.carrierDocument.name
      this.documentType = {
        id: this.carrierDocument.documentType,
        name: this.carrierDocumentdocumentTypeDisplay,
      }
      this.fileURL = this.carrierDocument.file
    },
  },
  mounted() {
    this.loadDocumentData()
  },
  methods: {
    loadDocumentData() {
      this.$store.dispatch("carrier/GET_CARRIER_DOCUMENTS", this.carrierId)
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM-DD-YYYY")
      }
    },
    showAddDocument() {
      this.addDocument = true
    },
    uploadDocument() {
      if (this.$refs["carrier-document-upload"].validate()) {
        if (this.documentId === null) {
          const flData = new FormData()
          flData.append("file", this.fileInput)
          flData.append("name", this.documentName)
          flData.append("document_type", this.documentType.id)
          const documentPayload = {
            carrierId: this.carrierId,
            fmData: flData,
          }
          this.$store
            .dispatch("carrier/ADD_CARRIER_DOCUMENT", documentPayload)
            .then(() => {
              this.addDocument = false
              this.hasEdit = false
              this.documentName = ""
              this.fileInput = null
              this.documentType = null
            })
        } else {
          const flData = new FormData()
          if (this.fileInput !== null) {
            flData.append("file", this.fileInput)
          }
          flData.append("name", this.documentName)
          flData.append("document_type", this.documentType.id)
          const documentPayload = {
            carrierId: this.carrierId,
            id: this.documentId,
            fmData: flData,
          }
          this.$store.dispatch(
            "carrier/UPDATE_CARRIER_DOCUMENT",
            documentPayload
          )
          this.documentId = null
          this.addDocument = false
          this.hasEdit = false
        }
      }
    },
    cancelUpload() {
      this.addDocument = false
      this.index = -1
      this.documentId = null
      this.hasEdit = false
      this.documentType = null
      this.fileInput = null
      this.documentName = ""
    },
    removeRecord() {
      if (this.index > -1) {
        const item = this.documents[this.index]
        const payload = {
          id: item.id,
          carrierId: this.carrierId,
        }
        this.$store.dispatch("carrier/DELETE_CARRIER_DOCUMENT", payload)
        this.dialog = false
      }
    },
    deleteDocument(item) {
      this.index = this.documents.indexOf(item)
      this.dialog = true
    },
    editDocument(item) {
      this.documentId = item.id
      this.$store.dispatch("carrier/GET_CARRIER_DOCUMENT_DETAIL", item.id)
      this.addDocument = true
      this.hasEdit = true
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
</style>
