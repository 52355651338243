<template>
  <v-container fluid class="pt-1">
    <v-row>
      <v-col cols="12" class="text-right" v-if="canPerformActions">
        <v-btn
          text
          class="blue--text text--lighten-2 font-weight-bold mt-4"
          @click="addNewPaymentMethod()"
        >
          Add payment method
        </v-btn>
      </v-col>
      <v-col class="pt-0">
        <v-container
          id="tblPayment"
          fluid
          class="pl-0 pr-0 pt-0 data-container"
          style="overflow-y: auto; max-height: 550px"
        >
          <v-data-table
            id="tblData"
            :headers="headers"
            :items="paymentMethods"
            class="elevation-2 data-table"
            calculate-widths
            hide-default-footer
            fixed-header
            disable-sort
          >
            <template v-slot:item.name="{ item }">
                {{ item.name }}
            </template>
            <template v-slot:item.paymentMethod="{ item }">
                <span style="text-transform: uppercase;">
                  {{ item.paymentMethod }}
                </span>
            </template>
            <template v-slot:item.description="{ item }">
                <span v-if="item.paymentMethod === 'ACH'" class="ach-description">
                  <span>
                    <span class="description-label">Account type: </span>
                    <span>{{ item.accountType }}</span>
                  </span>
                  <span>
                    <span class="description-label">Routing number: </span>
                    <span>{{ item.routingNumber }}</span>
                  </span>
                  <span>
                    <span class="description-label">Account number: </span>
                    <span>{{ item.accountNumber }}</span>
                  </span>
                </span>
                <span v-else class="check-description">
                  <span>
                    <span class="description-label">Business: </span>
                    <span>{{ item.businessName }}</span>
                  </span>
                  <template v-if="item.paymentMethod === 'FACTORING'">
                    <span>
                      <span class="description-label">Contact: </span>
                      <span>{{ item.contactName || "N/A" }}</span>
                    </span>
                    <span>
                      <span class="description-label">Email: </span>
                      <span>{{ item.email || "N/A" }}</span>
                    </span>
                    <span>
                      <span class="description-label">Phone: </span>
                      <span>{{ item.phone || "N/A" }}</span>
                    </span>
                  </template>
                  <span v-else />
                  <span>
                    <span class="description-label">Street address: </span>
                    <span>{{ item.addressLine1 }}</span>
                  </span>
                  <span>
                    <span class="description-label">Apt/Unit/Suite: </span>
                    <span>{{ item.addressLine2 || "N/A" }}</span>
                  </span>
                  <span>
                    <span class="description-label">Country: </span>
                    <span>{{ item.country }}</span>
                  </span>
                  <span>
                    <span class="description-label">City: </span>
                    <span>{{ item.city }}</span>
                  </span>
                  <span>
                    <span class="description-label">State: </span>
                    <span>{{ item.state }}</span>
                  </span>
                  <span>
                    <span class="description-label">Postal code: </span>
                    <span>{{ item.postalCode }}</span>
                  </span>
                </span>
            </template>
            <template v-slot:item.actions="{ item }" v-if="canPerformActions">
              <v-tooltip left :disabled="!item.isPrimary">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      small
                      color="warning"
                      :disabled="item.isPrimary"
                      style="margin-right: 1.2rem !important;"
                      :loading="paymentMethodIsBeingSetAsPrimary(item.id)"
                      @click="setAsPrimary(item.id)"
                    >SET PRIMARY</v-btn>
                  </span>
                </template>
                <span>Payment method already primary</span>
              </v-tooltip>
              <v-tooltip left :disabled="!item.isPrimary">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      small
                      color="red"
                      style="margin-right: 1.2rem !important;"
                      :loading="paymentMethodIsDeleting(item.id)"
                      @click="deletePaymentMethod(item.id)"
                      :disabled="item.isPrimary"
                    >DELETE</v-btn>
                  </span>
                </template>
                <span>Cannot delete primary payment method</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-container>
      </v-col>
    </v-row>
    <create-payment-method :carrier-id="carrierId" :show.sync="showUpsertDialog"/>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import CreatePaymentMethod from "./CreatePaymentMethod"

export default {
  name: "payment-methods",
  components: {
    CreatePaymentMethod
  },
  props: {
    carrierId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      showUpsertDialog: false,
    }
  },
  computed: {
    ...mapGetters("carrier", [
      "paymentMethods",
      "paymentMethodIsDeleting",
      "paymentMethodIsBeingSetAsPrimary"
    ]),
    ...mapGetters("generic", ["agentDetail"]),
    canPerformActions() {
     return this.agentDetail.accounting || this.agentDetail.admin
    },
    headers(){
      return [
        { text: "Nickname", value: "name", width: 300 },
        { text: "Method", value: "paymentMethod", width: 100 },
        { text: "Description", value: "description" },
        ...this.canPerformActions ? [{ text: "Actions", value: "actions", width: 200 }] : [],
      ]
    }
  },
  mounted() {
    this.$store.dispatch("carrier/GET_PAYMENT_METHODS", this.carrierId)
  },
  methods: {
    addNewPaymentMethod() {
      this.showUpsertDialog = true
    },
    deletePaymentMethod(paymentMethodId) {
      this.$store.dispatch("carrier/DELETE_PAYMENT_METHOD", { paymentMethodId, carrierId: this.carrierId })
    },
    setAsPrimary(paymentMethodId) {
      this.$store.dispatch("carrier/SET_PRIMARY_PAYMENT_METHOD", { paymentMethodId, carrierId: this.carrierId })
    },
  },
}
</script>

<style>
  .ach-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 1rem 0;
  }

  .check-description {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    padding: 1rem 0;
  }

  .description-label {
    font-weight: bold;
  }
</style>