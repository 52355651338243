<template>
  <div id="create-payment-method">
    <v-dialog :value="show" @input="closeDialog" width="600">
      <v-card>
        <v-card-title style="background-color: #1e88e5">
          <v-row class="align-center">
            <v-col cols="12">
              <span class="text-h2 white--text">Add Payment Method</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-radio-group @change="setSelectedPaymentMethodChoice" :value="selectedPaymentMethodChoice" row>
            <v-radio
              label="ACH"
              value="ACH"
            />
            <v-radio
              label="Check"
              value="CHECK"
            />
            <v-radio
              label="Factoring"
              value="FACTORING"
            />
          </v-radio-group>
          <v-col class="pb-0" cols="12">
            <v-text-field
              :value="paymentMethod.name"
              @input="setPaymentMethodField('name', $event)"
              :rules="[rules.required, rules.alphaNumeric]"
              class="pt-1"
              color="info"
              label="Nickname"
            />
          </v-col>
          <component :is="formComponent" :payment-method-choice="selectedPaymentMethodChoice"/>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn color="red" text @click="closeDialog()"> Cancel </v-btn>
          <v-btn
            color="info"
            text
            @click="submit"
            :disabled="!formIsValid"
            :loading="isAddingPaymentMethod"
          > Add </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
import CreatePaymentMethodACHForm from "./CreatePaymentMethodACHForm"
import CreatePaymentMethodAddressForm from "./CreatePaymentMethodAddressForm"

export default {
  name: "CreatePaymentMethod",
  components: {
    CreatePaymentMethodACHForm,
    CreatePaymentMethodAddressForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    carrierId: {
      type: [String, Number]
    },
  },
  data() {
    return {
      rules: validation.rules,
    }
  }, 
  computed: {
    ...mapGetters("carrier", [
      "selectedPaymentMethodChoice",
      "paymentMethod",
      "formIsValid",
      "isAddingPaymentMethod",
    ]),
    formComponent() {
      return this.selectedPaymentMethodChoice === "ACH" ? CreatePaymentMethodACHForm : CreatePaymentMethodAddressForm
    },
  },
  methods: {
    async submit() {
      await this.$store.dispatch("carrier/ADD_PAYMENT_METHOD", this.carrierId)
      this.closeDialog()
    },
    setSelectedPaymentMethodChoice(paymentMethod){
      this.$store.commit("carrier/setSelectedPaymentMethodChoice", paymentMethod)
    },
    closeDialog() {
      this.$emit("update:show", false)
    },
    setPaymentMethodField(field, value){
      this.$store.commit("carrier/setCheckFormField", { field, value })
      this.$store.commit("carrier/setACHFormField", { field, value })
      this.$store.commit("carrier/setFactoringFormField", { field, value })
    }
  },
}
</script>
<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}
.v-textarea /deep/ .v-text-field__slot textarea {
  resize: none;
}
</style>
