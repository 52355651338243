<template>
  <v-container fluid>
    <template v-for="(lane, key) in lanesList">
      <v-card :key="key">
        <v-card-text>
          <v-row v-if="key === 0">
            <v-col
              cols="10"
              sm="10"
              md="10"
              lg="10"
              xl="10"
              class="blue--text text--lighten-2 font-size-14"
            >
              <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
                mdi-highway
              </v-icon>
              Lanes
            </v-col>
            <v-col cols="2">
              <v-btn
                small
                text
                class="font-weight-bold float-right"
                style="color: #1e88e5"
                @click="addLane"
              >
                <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
                  mdi-plus-circle-outline
                </v-icon>
                Add Lane
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <v-autocomplete
                v-model="lane.srcLocation.currentPlace"
                :items="lane.originLocationList"
                color="info"
                :search-input.sync="lane.originQuery"
                clearable
                item-text="title"
                item-value="id"
                label="Origin"
                return-object
                required
                close-on-content-click
                class="pt-0 pb-0"
                item-width="200"
                :filter="(v) => v"
                @update:search-input="originQuerySearch(lane.originQuery, key)"
                @change="setOriginPlace(key)"
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.title" />
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
              <v-autocomplete
                v-model="lane.dstLocation.currentPlace"
                :items="lane.destinationLocationList"
                :search-input.sync="lane.destinationQuery"
                color="info"
                clearable
                item-text="title"
                item-value="id"
                label="Destination"
                return-object
                required
                close-on-content-click
                class="pt-0 pb-0"
                item-width="200"
                :filter="(v) => v"
                @update:search-input="
                  destinationQuerySearch(lane.destinationQuery, key)
                "
                @change="setDestinationPlace(key)"
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.title" />
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" xl="2">
              <v-select
                v-model="lane.equipmentType"
                :items="equipmentTypes"
                label="Equipment Types"
                item-text="name"
                item-value="id"
                dense
                return-object
                class="mt-2 px-4"
                style="text-transform: none"
              />
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1">
              <v-text-field
                v-model="lane.miles"
                disabled
                type="text"
                label="Miles"
                class="pt-0 px-4"
              />
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" xl="1">
              <v-text-field
                v-model="lane.carrierBid"
                type="text"
                label="Price"
                class="pt-0 px-4"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" xl="2">
              <v-btn
                text
                small
                class="
                  red--text
                  text--lighten-2
                  font-weight-bold
                  float-right
                  mt-4
                "
                @click="removeLane(key)"
              >
                Remove
              </v-btn>
              <v-btn
                text
                small
                style="color: #1e88e5"
                class="font-weight-bold float-right mt-4"
                @click="saveSingleLane(key)"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-text> Please confirm lane deletion </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text small @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="orange darken-1"
            text
            small
            @click="removeRecord(index)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"
import common from "@/utils/common"

export default {
  name: "Lanes",
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      lanesList: [
        {
          id: 0,
          srcLocation: {
            id: 0,
            name: null,
            address: null,
            city: null,
            stateProvinceRegion: null,
            postalCode: null,
            country: null,
            latitude: null,
            longitude: null,
            currentPlace: null,
          },
          dstLocation: {
            id: null,
            name: null,
            address: null,
            city: null,
            stateProvinceRegion: null,
            postalCode: null,
            country: null,
            latitude: null,
            longitude: null,
            currentPlace: null,
          },
          createdAt: null,
          updatedAt: null,
          expiredAt: null,
          archivedAt: null,
          srcDate: null,
          dstDate: null,
          quantityTrucks: 0,
          quantityLoads: 0,
          carrierBid: null,
          source: null,
          sourceDisplay: "",
          equipmentType: null,
          matchType: null,
          matchTypeDisplay: "",
          miles: 0,
          originLocationList: [],
          destinationLocationList: [],
          originQuery: "",
          destinationQuery: "",
        },
      ],
      shipperInfo: null,
      consigneeInfo: null,
      dialog: false,
      index: 0,
      hereMapPlatform: null,
      currentLane: null,
    }
  },

  computed: {
    ...mapGetters("carrier", ["carrierLanes", "overlay"]),
    ...mapGetters("generic", ["equipmentTypes"]),
  },

  watch: {
    carrierLanes() {
      if (this.carrierLanes.results.length === 0) {
        this.lanesList = []
        this.addLane()
      } else {
        this.lanesList = this.carrierLanes.results
      }
    },
  },

  async mounted() {
    this.initHereMapPlatform()
    await this.loadLaneData()
  },

  methods: {
    initHereMapPlatform() {
      this.hereMapPlatform = new window.H.service.Platform({
        apikey: process.env.VUE_APP_HERE_MAP_KEY,
      })
    },

    async loadLaneData() {
      const payload = `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/lane/${this.carrierId}/`
      await this.$store.dispatch("carrier/GET_CARRIER_LANES", payload)

      for (let i = 0; i < this.lanesList.length; i++) {
        if (
          this.lanesList[i].srcLocation.currentPlace &&
          this.lanesList[i].srcLocation.currentPlace.title !== undefined
        ) {
          this.lanesList[i].originLocationList.push(
            this.lanesList[i].srcLocation.currentPlace
          )
          this.lanesList[i].destinationLocationList.push(
            this.lanesList[i].dstLocation.currentPlace
          )
        } else {
          this.lanesList[i].srcLocation.currentPlace.title =
            this.lanesList[i].srcLocation.name
          this.lanesList[i].srcLocation.currentPlace.id =
            this.lanesList[i].srcLocation.id
          this.lanesList[i].originLocationList.push(
            this.lanesList[i].srcLocation.currentPlace
          )

          this.lanesList[i].dstLocation.currentPlace.title =
            this.lanesList[i].dstLocation.name
          this.lanesList[i].dstLocation.currentPlace.id =
            this.lanesList[i].dstLocation.id
          this.lanesList[i].destinationLocationList.push(
            this.lanesList[i].dstLocation.currentPlace
          )
        }
      }
    },

    addLane() {
      this.lanesList.push({
        id: 0,
        srcLocation: {
          id: 0,
          name: null,
          address: null,
          city: null,
          stateProvinceRegion: null,
          postalCode: null,
          country: null,
          latitude: null,
          longitude: null,
          currentPlace: null,
        },
        dstLocation: {
          id: null,
          name: null,
          address: null,
          city: null,
          stateProvinceRegion: null,
          postalCode: null,
          country: null,
          latitude: null,
          longitude: null,
          currentPlace: null,
        },
        createdAt: null,
        updatedAt: null,
        expiredAt: null,
        archivedAt: null,
        srcDate: null,
        dstDate: null,
        quantityTrucks: 0,
        quantityLoads: 0,
        rate: null,
        source: null,
        source_display: "",
        equipmentType: "",
        matchType: null,
        matchTypeDisplay: "",
        miles: 0,
        originLocationList: [],
        destinationLocationList: [],
        originQuery: "",
        destinationQuery: "",
      })
    },

    removeLane(value) {
      this.index = value
      this.dialog = true
    },

    getDisplayPlaceFromLocation(place) {
      const location = place.address_components
      return `${location[0].long_name}, ${location[2].short_name}`
    },

    setOriginPlaceGmap(place, key) {
      this.lanesList[key].srcLocation.latitude = parseFloat(
        place.geometry.location.lat()
      )
      this.lanesList[key].srcLocation.longitude = parseFloat(
        place.geometry.location.lng()
      )
      this.lanesList[key].srcLocation.currentPlace = place
      this.lanesList[key].srcLocation.displayPlace =
        this.getDisplayPlaceFromLocation(place)
    },

    setOriginPlace(key) {
      const currentPlace = this.lanesList[key].srcLocation.currentPlace
      this.lanesList[key].srcLocation.latitude = parseFloat(
        currentPlace.position.lat
      )
      this.lanesList[key].srcLocation.longitude = parseFloat(
        currentPlace.position.lng
      )
    },

    setDestinationPlaceGmap(place, key) {
      this.lanesList[key].dstLocation.latitude = parseFloat(
        place.geometry.location.lat()
      )
      this.lanesList[key].dstLocation.longitude = parseFloat(
        place.geometry.location.lng()
      )
      this.lanesList[key].dstLocation.currentPlace = place
      this.lanesList[key].dstLocation.displayPlace =
        this.getDisplayPlaceFromLocation(place)
    },

    setDestinationPlace(key) {
      const currentPlace = this.lanesList[key].dstLocation.currentPlace
      this.lanesList[key].dstLocation.latitude = parseFloat(
        currentPlace.position.lat
      )
      this.lanesList[key].dstLocation.longitude = parseFloat(
        currentPlace.position.lng
      )
    },

    removeRecord(index) {
      if (this.lanesList[index].id !== 0 && this.lanesList[index].id !== null) {
        this.$store.dispatch(
          "carrier/DELETE_CARRIER_LANE",
          this.lanesList[index].id
        )
        this.lanesList.splice(index, 1)
        if (this.lanesList.length === 0) {
          this.addLane()
        }
      } else {
        if (this.lanesList.length > 1) {
          this.lanesList.splice(index, 1)
        } else {
          const snackbar = {
            status: true,
            text: "Carrier doesn't have any lanes.",
            type: common.data.snackbarType.error,
          }
          this.$store.commit("main/SET_SNACKBAR", snackbar)
        }
      }
      this.dialog = false
    },

    async saveSingleLane(index) {
      let lanePayload = {}
      const lane = this.lanesList[index]
      if (lane.id === 0) {
        if (
          lane.srcLocation.currentPlace !== null &&
          lane.dstLocation.currentPlace !== null
        ) {
          lanePayload = {
            carrier_company: this.carrierId,
            src_location: {
              latitude: lane.srcLocation.latitude,
              longitude: lane.srcLocation.longitude,
              notes: "",
              current_place: lane.srcLocation.currentPlace,
            },
            dst_location: {
              latitude: lane.dstLocation.latitude,
              longitude: lane.dstLocation.longitude,
              notes: "",
              current_place: lane.dstLocation.currentPlace,
            },
            src_date: moment(Date(new Date())).format("YYYY-MM-DD"),
            quantity_trucks: lane.quantityTrucks,
            quantity_loads: lane.quantityLoads,
            source: 1,
            equipment_type: lane.equipmentType.id,
            match_type: 3,
            carrier_bid: lane.carrierBid === '' ? null : lane.carrierBid,
          }
          await this.$store.dispatch("carrier/ADD_CARRIER_LANE", lanePayload)
          await this.loadLaneData()
        } else {
          const snackbar = {
            status: true,
            text: "Source and Destination location required",
            type: common.data.snackbarType.error,
          }
          this.$store.commit("main/SET_SNACKBAR", snackbar)
        }
      } else {
        if (
          lane.srcLocation.currentPlace !== null &&
          lane.dstLocation.currentPlace !== null
        ) {
          lanePayload = {
            id: lane.id,
            data: {
              carrier_company: this.carrierId,
              src_location: {
                latitude: lane.srcLocation.latitude,
                longitude: lane.srcLocation.longitude,
                notes: "",
                current_place: lane.srcLocation.currentPlace,
              },
              dst_location: {
                latitude: lane.dstLocation.latitude,
                longitude: lane.dstLocation.longitude,
                notes: "",
                current_place: lane.dstLocation.currentPlace,
              },
              src_date: lane.srcDate,
              quantity_trucks: lane.quantityTrucks,
              quantity_loads: lane.quantityLoads,
              source: lane.source,
              equipment_type: lane.equipmentType.id,
              match_type: 3,
              carrier_bid: lane.carrierBid === '' ? null : lane.carrierBid,
            },
          }
          this.$store.dispatch("carrier/UPDATE_CARRIER_LANE", lanePayload)
        } else {
          const snackbar = {
            status: true,
            text: "Source and Destination location required",
            type: common.data.snackbarType.error,
          }
          this.$store.commit("main/SET_SNACKBAR", snackbar)
        }
      }
    },

    originLocationGeocode(value) {
      const geocoder = this.hereMapPlatform.getSearchService()
      const geocodingParameters = {
        q: value,
        in: "countryCode:CAN,MEX,USA",
      }
      geocoder.geocode(geocodingParameters, this.onSuccessOrigin, this.onError)
    },

    destinationLocationGeocode(value) {
      const geocoder = this.hereMapPlatform.getSearchService()
      const geocodingParameters = {
        q: value,
        in: "countryCode:CAN,MEX,USA",
      }
      geocoder.geocode(
        geocodingParameters,
        this.onSuccessDestination,
        this.onError
      )
    },

    onSuccessOrigin(result) {
      this.lanesList[this.currentLane].originLocationList = result.items
    },

    onSuccessDestination(result) {
      this.lanesList[this.currentLane].destinationLocationList = result.items
    },

    onError() {
      alert("Can't reach the remote server")
    },

    originQuerySearch(text, key) {
      if (this.lanesList[key].originLocationList.length > 0) {
        if (this.lanesList[key].originLocationList[0].title !== text) {
          this.currentLane = key
          if (text) {
            this.originLocationGeocode(text)
          }
        }
      } else {
        this.currentLane = key
        if (text) {
          this.originLocationGeocode(text)
        }
      }
    },

    destinationQuerySearch(text, key) {
      if (this.lanesList[key].destinationLocationList.length > 0) {
        if (this.lanesList[key].destinationLocationList[0].title !== text) {
          this.currentLane = key
          if (text) {
            this.destinationLocationGeocode(text)
          }
        }
      } else {
        this.currentLane = key
        if (text) {
          this.destinationLocationGeocode(text)
        }
      }
    },
  },
}
</script>
