<template>
  <div class="matches-detail">
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown ? false : true"
      transition="slide-x-reverse-transition"
      content-class="match-detail-dialog"
    >
      <v-toolbar dark style="background-color: #1e88e5">
        <v-btn icon dark @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
          <p />
        </v-btn>
        <v-toolbar-title v-if="dialogLoad">
          <b>{{ dialogLoad.customerCompany }} | {{ loadId }}</b> -
          {{ dialogLoad.equipmentType }}
        </v-toolbar-title>
      </v-toolbar>
      <v-container v-if="dialogLoad" fluid class="pb-0">
        <v-row style="background-color: #ffffff">
          <v-col cols="12" md="6">
            <v-list-item-title class="text-h5 mb-1">
              <v-icon color="green" x-large> mdi-map-marker </v-icon>
              Origin
            </v-list-item-title>
            <p class="mb-0">
              {{ dialogLoad.shipper.city }},
              {{ dialogLoad.shipper.state_province_region }} |
              {{ formatDate(dialogLoad.pickupDate) }}
            </p>
            <p class="mb-0">
              {{ dialogLoad.pickupEarlyTime }} -
              {{ dialogLoad.pickupLateTime }}
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item-title class="text-h5 mb-1">
              <v-icon color="red" x-large> mdi-map-marker </v-icon>
              Destination
            </v-list-item-title>
            <p class="mb-0">
              {{ dialogLoad.consignee.city }},
              {{ dialogLoad.consignee.state_province_region }} |
              {{ formatDate(dialogLoad.deliveryDate) }}
            </p>
            <p class="mb-0">
              {{ dialogLoad.deliveryEarlyTime }} -
              {{ dialogLoad.deliveryLateTime }}
            </p>
          </v-col>
        </v-row>
        <v-row style="background-color: #ffffff">
          <v-col cols="4" md="2" class="pb-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Agent</b>
            </v-list-item-title>
            <p>{{ dialogLoad.agentName }}</p>
          </v-col>
          <v-col cols="4" md="2" class="pb-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Load Status</b>
            </v-list-item-title>
            <p>{{ dialogLoad.loadStatus }}</p>
          </v-col>
          <v-col cols="4" md="2" class="pb-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Customer Ref.</b>
            </v-list-item-title>
            <p>{{ dialogLoad.customerReferenceId }}</p>
          </v-col>
          <v-col cols="4" md="2" class="pb-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Carrier Buy</b>
            </v-list-item-title>
            <p>
              {{ dialogLoad.carrierStartBuy }} -
              {{ dialogLoad.carrierMaxBuy }}
            </p>
          </v-col>
          <v-col cols="4" md="2" class="pb-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Customer Pay</b>
            </v-list-item-title>
            <p>{{ dialogLoad.customerPrice }}</p>
          </v-col>
          <v-col cols="4" md="2" class="pb-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Miles</b>
            </v-list-item-title>
            <p>{{ dialogLoad.miles }}</p>
          </v-col>
        </v-row>
        <v-row style="background-color: #ffffff">
          <v-col cols="4" md="2" class="pt-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Height</b>
            </v-list-item-title>
            <p>{{ dialogLoad.loadHeight }}</p>
          </v-col>
          <v-col cols="4" md="2" class="pt-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Weight</b>
            </v-list-item-title>
            <p>{{ dialogLoad.loadWeight }}</p>
          </v-col>
          <v-col cols="4" md="2" class="pt-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Length</b>
            </v-list-item-title>
            <p>{{ dialogLoad.loadLength }}</p>
          </v-col>
          <v-col cols="4" md="2" class="pt-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Width</b>
            </v-list-item-title>
            <p>{{ dialogLoad.loadWidth }}</p>
          </v-col>
          <v-col cols="8" md="4" class="pt-0">
            <v-list-item-title class="text-h4 mb-1">
              <b>Commodity</b>
            </v-list-item-title>
            <p>{{ dialogLoad.commodity }}</p>
          </v-col>
        </v-row>
        <v-row style="border-top: solid 1px #ddd; height: 100%">
          <v-container>
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Backhual Loads
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  style="max-height: 400px; overflow-y: auto"
                >
                  <v-data-table
                    :headers="backhualHeaders"
                    :items="backhualShipments"
                    :loading="backhualLoading"
                    single-expand
                    show-expand
                    calculate-widths
                    fixed-header
                    hide-default-footer
                    :items-per-page="backhualPage"
                  >
                    <template v-slot:item.id="{ item }">
                      <v-btn
                        text
                        small
                        target="_blank"
                        :to="{
                          name: 'Load Detail',
                          params: { id: item.id },
                        }"
                      >
                        {{ item.id }}
                      </v-btn>
                    </template>
                    <template v-slot:item.shipper="{ item }">
                      <p v-if="item.shipper !== null">
                        {{ item.shipper.city }},
                        {{ item.shipper.state_province_region }}
                        <br />
                        <span class="subtitle">{{ item.pickupDate }}</span>
                      </p>
                    </template>
                    <template v-slot:item.consignee="{ item }">
                      <p v-if="item.consignee !== null">
                        {{ item.consignee.city }},
                        {{ item.consignee.state_province_region }}
                        <br />
                        <span class="subtitle">{{ item.deliveryDate }}</span>
                      </p>
                    </template>
                    <template v-slot:item.loadStatus="{ item }">
                      <load-status-edit-button
                        :shipment-id="item.id"
                        :load-status="item.loadStatus"
                        :parent-object="item"
                        :is-archived="item.archived"
                        @change="setLoadStatus"
                      />
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-row>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Agent</b>
                            </v-list-item-title>
                            <p>
                              {{ item.agentName }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Equipment Type</b>
                            </v-list-item-title>
                            <p>
                              {{ item.equipmentType }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Customer Ref.</b>
                            </v-list-item-title>
                            <p>
                              {{ item.customerReferenceId }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Carrier Buy</b>
                            </v-list-item-title>
                            <p>
                              {{ item.carrierStartBuy }}
                              -
                              {{ item.carrierMaxBuy }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Customer Pay</b>
                            </v-list-item-title>
                            <p>
                              {{ item.customerPrice }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Miles</b>
                            </v-list-item-title>
                            <p>{{ item.miles }}</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Height</b>
                            </v-list-item-title>
                            <p>
                              {{ item.loadHeight }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Weight</b>
                            </v-list-item-title>
                            <p>
                              {{ item.loadWeight }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Length</b>
                            </v-list-item-title>
                            <p>
                              {{ item.loadLength }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Width</b>
                            </v-list-item-title>
                            <p>
                              {{ item.loadWidth }}
                            </p>
                          </v-col>
                          <v-col cols="8" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Commodity</b>
                            </v-list-item-title>
                            <p>
                              {{ item.commodity }}
                            </p>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                  <v-page
                    :total-row="backhualTotalRecord"
                    :page-size-menu="[10, 20, 50, 100]"
                    class="pt-3 pr-3"
                    @page-change="backhualDataFromAPI"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Matching Loads
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  style="max-height: 400px; overflow-y: auto"
                >
                  <v-data-table
                    :headers="matchingHeaders"
                    :items="matchingShipments"
                    :loading="matchingLoading"
                    single-expand
                    show-expand
                    calculate-widths
                    fixed-header
                    hide-default-footer
                    :items-per-page="backhualPage"
                  >
                    <template v-slot:item.id="{ item }">
                      <v-btn
                        text
                        small
                        target="_blank"
                        :to="{
                          name: 'Load Detail',
                          params: { id: item.id },
                        }"
                      >
                        {{ item.id }}
                      </v-btn>
                    </template>
                    <template v-slot:item.shipper="{ item }">
                      <p v-if="item.shipper !== null">
                        {{ item.shipper.city }},
                        {{ item.shipper.state_province_region }}
                        <br />
                        <span class="subtitle">{{ item.pickupDate }}</span>
                      </p>
                    </template>
                    <template v-slot:item.consignee="{ item }">
                      <p v-if="item.consignee !== null">
                        {{ item.consignee.city }},
                        {{ item.consignee.state_province_region }}
                        <br />
                        <span class="subtitle">{{ item.deliveryDate }}</span>
                      </p>
                    </template>
                    <template v-slot:item.loadStatus="{ item }">
                      <load-status-edit-button
                        :shipment-id="item.id"
                        :load-status="item.loadStatus"
                        :parent-object="item"
                        :is-archived="item.archived"
                        @change="setLoadStatus"
                      />
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-row>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Agent</b>
                            </v-list-item-title>
                            <p>
                              {{ item.agentName }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Equipment Type</b>
                            </v-list-item-title>
                            <p>
                              {{ item.equipmentType }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Customer Ref.</b>
                            </v-list-item-title>
                            <p>
                              {{ item.customerReferenceId }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Carrier Buy</b>
                            </v-list-item-title>
                            <p>
                              {{ item.carrierStartBuy }}
                              -
                              {{ item.carrierMaxBuy }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Customer Pay</b>
                            </v-list-item-title>
                            <p>
                              {{ item.customerPrice }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Miles</b>
                            </v-list-item-title>
                            <p>{{ item.miles }}</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Height</b>
                            </v-list-item-title>
                            <p>
                              {{ item.loadHeight }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Weight</b>
                            </v-list-item-title>
                            <p>
                              {{ item.loadWeight }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Length</b>
                            </v-list-item-title>
                            <p>
                              {{ item.loadLength }}
                            </p>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Width</b>
                            </v-list-item-title>
                            <p>
                              {{ item.loadWidth }}
                            </p>
                          </v-col>
                          <v-col cols="8" md="2">
                            <v-list-item-title class="text-h4 mb-1">
                              <b>Commodity</b>
                            </v-list-item-title>
                            <p>
                              {{ item.commodity }}
                            </p>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                  <v-page
                    :total-row="matchingTotalRecord"
                    :page-size-menu="[10, 20, 50, 100]"
                    class="pt-3 pr-3"
                    @page-change="matchingDataFromAPI"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import moment from "moment"
import LoadStatusEditButton from "@/views/pages/load-detail-components/edit-components/LoadStatusEditButton"
export default {
  name: "RelatedLoads",
  components: {
    LoadStatusEditButton,
  },
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      panel: [0, 1],
      loadId: 0,
      backhualLoading: false,
      matchingLoading: false,
      backhualHeaders: [
        { text: "Customer", value: "customerCompany", sortable: false },
        {
          text: "Load Number",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          value: "loadStatus",
          align: "center",
          sortable: false,
        },
        {
          text: "Origin",
          value: "shipper",
          align: "center",
          sortable: false,
        },
        {
          text: "Destination",
          value: "consignee",
          align: "center",
          sortable: false,
        },
      ],
      backhualShipments: [],
      matchingHeaders: [
        { text: "Customer", value: "customerCompany", sortable: false },
        {
          text: "Load Number",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          value: "loadStatus",
          align: "center",
          sortable: false,
        },
        {
          text: "Origin",
          value: "shipper",
          align: "center",
          sortable: false,
        },
        {
          text: "Destination",
          value: "consignee",
          align: "center",
          sortable: false,
        },
      ],
      matchingShipments: [],
      backhualQueryParams: {},
      backhualTotalRecord: 0,
      backhualPageSize: 10,
      backhualNumberOfPages: 0,
      backhualPage: 1,
      matchingQueryParams: {},
      matchingTotalRecord: 0,
      matchingPageSize: 10,
      matchingNumberOfPages: 0,
      matchingPage: 1,
    }
  },
  computed: {
    ...mapGetters("carrierMatching", {
      dialog: "matchDialog",
      dialogLoad: "dialogLoad",
      backhualLoads: "backhualLoads",
      matchingLoads: "matchingLoads",
    }),
  },
  watch: {
    dialogLoad() {
      this.getRelatedLoads(this.dialogLoad.id)
      this.loadId = this.dialogLoad.id
    },
    backhualLoads() {
      this.backhualTotalRecord = this.backhualLoads.count
      this.backhualNumberOfPages = Math.ceil(
        this.backhualLoads.count / this.backhualPageSize
      )
      this.backhualShipments = this.backhualLoads.results
    },
    matchingLoads() {
      this.matchingTotalRecord = this.matchingLoads.count
      this.matchingNumberOfPages = Math.ceil(
        this.matchingLoads.count / this.matchingPageSize
      )
      this.matchingShipments = this.matchingLoads.results
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("dddd, MMMM Do, YYYY")
    },
    closeDialog() {
      this.$store.commit("carrierMatching/setMatchDialog", false)
    },
    getRelatedLoads(loadId) {
      this.backhualQueryParams = {
        carrierId: this.carrierId,
        loadIds: loadId,
        limit: this.backhualPageSize,
        offset: 0,
      }
      this.backhualLoading = true
      this.$store
        .dispatch(
          "carrierMatching/GET_BACKHUAL_LOADS",
          this.backhualQueryParams
        )
        .then(() => {
          this.backhualLoading = false
        })
        .catch(() => {
          this.backhualLoading = false
        })

      this.matchingQueryParams = {
        carrierId: this.carrierId,
        loadIds: loadId,
        limit: this.backhualPageSize,
        offset: 0,
      }
      this.matchingLoading = true
      this.$store
        .dispatch(
          "carrierMatching/GET_MATCHING_LOADS",
          this.matchingQueryParams
        )
        .then(() => {
          this.matchingLoading = false
        })
        .catch(() => {
          this.matchingLoading = false
        })
    },
    backhualDataFromAPI(value) {
      if (this.backhualShipments.length > 0) {
        this.backhualPage = value.pageNumber
        this.backhualPageSize = value.pageSize
        let pageNumber =
          this.backhualPageSize * this.backhualPage - this.backhualPageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.backhualQueryParams.offset = pageNumber
        this.backhualQueryParams.limit = this.backhualPageSize
        this.backhualLoading = true
        this.$store
          .dispatch(
            "carrierMatching/GET_BACKHUAL_LOADS",
            this.backhualQueryParams
          )
          .then(() => {
            this.backhualLoading = false
          })
          .catch(() => {
            this.backhualLoading = false
          })
      }
    },
    matchingDataFromAPI(value) {
      if (this.matchingShipments.length > 0) {
        this.matchingPage = value.pageNumber
        this.matchingPageSize = value.pageSize
        let pageNumber =
          this.matchingPageSize * this.matchingPage - this.matchingPageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.matchingQueryParams.offset = pageNumber
        this.matchingQueryParams.limit = this.matchingPageSize
        this.matchingLoading = true
        this.$store
          .dispatch(
            "carrierMatching/GET_MATCHING_LOADS",
            this.matchingQueryParams
          )
          .then(() => {
            this.matchingLoading = false
          })
          .catch(() => {
            this.matchingLoading = false
          })
      }
    },
    setLoadStatus(event) {
      this.$store.commit("loadDetail/setLoadStatusUpdate", event.loadStatus)
    },
  },
}
</script>
<style>
.v-dialog--fullscreen {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  left: 50% !important;
  width: 50% !important;
}
.match-detail-dialog {
  background-color: #f8f9fa;
}
.v-expansion-panel-content__wrap {
  padding: 0 8px 16px;
}
.dialog-fullscreen {
  left: 0 !important;
  width: 100% !important;
}
</style>
