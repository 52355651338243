<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card min-height="512" class="mt-0">
          <v-card-title class="blue--text text--lighten-2">
            Stats
          </v-card-title>
          <v-card-text>
            <p class="col-md-12 mb-0 pb-0 pl-0">
              <b>Total Shipments:</b>
              {{ carrierStats.totalShipment }}
            </p>
            <p class="col-md-12 mb-0 pb-0 pl-0">
              <b>Margin %:</b> {{ carrierStats.averageMargin }}
            </p>
            <p class="col-md-12 mb-0 pb-0 pl-0">
              <b>Send Offers:</b>
              {{ currentCarrier.sendOffers ? "Yes" : "No" }}
            </p>
            <p class="col-md-12 mb-0 pb-0 pl-0">
              <b>Days Between Emails:</b>
              {{ currentCarrier.daysBetweenEmails }} Day(s)
            </p>
            <p class="col-md-12 mb-0 pb-0 pl-0">
              <b>Insurance Status:</b>
              {{ currentCarrier.insuranceActive }}
            </p>
            <p class="col-md-12 mb-0 pb-0 pl-0">
              <b>Approved:</b> {{ currentCarrier.approved }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="5">
        <v-card min-height="512" class="mt-0">
          <v-card-title class="blue--text text--lighten-2">
            Related Customers
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="5" class="py-0">
                  <customer-auto-complete />
                </v-col>

                <v-col cols="5" class="py-0">
                  <v-select
                    v-model="network"
                    label="Network Type"
                    :items="networkOptions"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col cols="2">
                  <v-btn
                    small
                    class="
                      while--text
                      blue
                      text--lighten-2
                      font-weight-bold
                      mt-2
                      py-0
                    "
                    @click="addToCarrier()"
                  >
                    Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-row>
              <v-col cols="12" class="pt-0">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  class="pt-0"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div style="min-height: 335px; overflow-y: auto">
                  <v-data-table
                    :headers="headers"
                    :items="currentCarrier.customerCompanies"
                    class="elevation-1 mx-1"
                    calculate-widths
                    fixed-header
                    :items-per-page="5"
                    :footer-props="{
                      'items-per-page-options': [5],
                    }"
                    :search="search"
                  >
                    <template v-slot:item.network="{ item }">
                      {{ item.network.length ? item.network[0].type_text : "" }}
                    </template>
                    <template v-slot:item.id="{ item }">
                      <v-btn
                        small
                        class="while--text red text--lighten-2 font-weight-bold"
                        @click="removeFromCarrier(item.id)"
                      >
                        Remove
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card min-height="512" class="mt-0">
          <v-card-title class="blue--text text--lighten-2">
            Shipments
            <v-spacer />
            <v-btn
              small
              class="float-right blue--text text--lighten-2"
              text
              @click="viewAllShipments"
            >
              View All
            </v-btn>
          </v-card-title>
          <v-card-text class="pb-0">
            <div v-if="recentShipments">
              <template v-for="(item, key) in recentShipments">
                <v-row
                  :key="key"
                  :style="key !== 4 ? 'border-bottom: 1px solid #E0E0E0' : ''"
                >
                  <p class="col-md-12 mb-0 pb-0 font-weight-bold">
                    {{ item.customerCompany }}
                  </p>
                  <p v-if="item.shipper" class="col-md-4 mb-0 pb-1 text-left">
                    {{
                      `${item.shipper.city}${
                        item.shipper.state_province_region
                          ? ", " + item.shipper.state_province_region
                          : ""
                      }`
                    }}
                  </p>
                  <p v-else style="min-width: 160px">-</p>
                  <v-icon class="col-md-2 mb-0 pb-1 text-left">
                    mdi-arrow-right-bold
                  </v-icon>
                  <p v-if="item.consignee" class="col-md-4 mb-0 pb-1 text-left">
                    {{
                      `${item.consignee.city}${
                        item.consignee.state_province_region
                          ? ", " + item.consignee.state_province_region
                          : ""
                      }`
                    }}
                  </p>
                  <p v-else style="min-width: 160px">-</p>
                  <p class="col-md-2 mb-0 pb-1 text-left">
                    <span v-if="item.carrierPrice">$</span
                    >{{ item.carrierPrice }}
                  </p>
                </v-row>
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"
import CustomerAutoComplete from "@/views/pages/components/CustomerAutoComplete"
import validation from "@/utils/form-validation"
import { NETWORK_TYPES } from "@/assets/constants"

export default {
  name: "Summary",
  components: {
    CustomerAutoComplete,
  },
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      search: null,
      recentDocuments: [
        {
          name: "Document 1",
          updatedAt: "09/01/2020",
        },
      ],
      recentShipments: [],
      headers: [
        {
          text: "Customer",
          align: "start",
          sortable: false,
          value: "name",
          width: "45%",
        },
        {
          text: "Network",
          align: "start",
          sortable: false,
          value: "network",
          width: "45%",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "id",
        },
      ],
      network: null,
      networkOptions: [
        {
          text: "Non Exo Network",
          value: NETWORK_TYPES.NON_EXO,
        },
        {
          text: "Private Network",
          value: NETWORK_TYPES.PRIVATE,
        },
      ],
      rules: validation.rules,
      valid: false,
    }
  },
  computed: {
    ...mapGetters("carrier", [
      "carrierStats",
      "currentCarrier",
      "carrierDocuments",
      "carrierShipments",
    ]),
    ...mapGetters("generic", ["customerId"]),
  },
  watch: {
    carrierDocuments() {
      this.recentDocuments = this.carrierDocuments.results
    },
    carrierShipments() {
      this.recentShipments = this.carrierShipments.results.slice(
        Math.max(this.carrierShipments.results.length - 5, 0)
      )
    },
  },
  mounted() {
    this.loadDataOnLoad()
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("MM-DD-YYYY")
      }
    },
    loadDataOnLoad() {
      this.$store.dispatch("carrier/GET_CARRIER_STATS", this.carrierId)
      this.$store.dispatch("carrier/GET_CARRIER_COMPANY", this.carrierId)
      const payload = {
        carrierId: this.carrierId,
        queryParams: {
          limit: 5,
          offset: 0,
          ordering: "-pickup_date",
        },
      }
      this.$store.dispatch("carrier/GET_CARRIER_SHIPMENTS", payload)
    },
    viewAllShipments() {
      this.$store.commit("carrier/setCurrentTab", "tab-3")
    },
    async addToCarrier() {
      if (!this.customerId || !this.$refs.form.validate()) {
        return
      }
      const payload = {
        id: this.currentCarrier.id,
        customerCompany: this.customerId,
        network: this.network,
      }
      await this.$store.dispatch("carrier/UPDATE_CUSTOMER_CARRIER", payload)
      this.network = null
      this.$refs.form.reset()
      this.$store.commit("generic/setCustomerId", null)
      this.$store.dispatch("carrier/GET_CARRIER_COMPANY", this.carrierId)
    },
    removeFromCarrier(customerId) {
      if (customerId) {
        const payload = {
          id: this.currentCarrier.id,
          customerCompany: customerId,
        }
        this.$store
          .dispatch("carrier/DELETE_CUSTOMER_FROM_CARRIER", payload)
          .then((data) => {
            this.$store.dispatch("carrier/GET_CARRIER_COMPANY", this.carrierId)
          })
      }
    },
  },
}
</script>
