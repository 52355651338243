<template>
  <v-container fluid class="pt-1">
    <v-row>
      <v-col class="pt-0">
        <v-container
          id="tblPayment"
          fluid
          class="pl-0 pr-0 pt-0 data-container"
          style="overflow-y: auto; max-height: 550px"
        >
          <v-data-table
            id="tblData"
            :headers="headers"
            :items="payments"
            class="elevation-2 data-table"
            calculate-widths
            hide-default-footer
            fixed-header
            :items-per-page="pageSize"
          >
            <template v-slot:item.loadNumber="{ item }">
              <v-btn
                text
                target="_blank"
                :to="{
                  name: 'Load Detail',
                  params: { id: item.loadNumber },
                }"
              >
                {{ item.loadNumber }}
              </v-btn>
            </template>
            <template v-slot:item.loadShipper="{ item }">
              <p v-if="item.loadShipper !== null" class="mb-0">
                {{ item.loadShipper }}
                <br />
                <template v-if="item.loadPickupDate !== null">
                  {{ formatDate(item.loadPickupDate) }}
                </template>
              </p>
            </template>
            <template v-slot:item.loadConsignee="{ item }">
              <p v-if="item.loadConsignee !== null" class="mb-0">
                {{ item.loadConsignee }}
                <br />
                <template v-if="item.loadDeliveryDate !== null">
                  {{ formatDate(item.loadDeliveryDate) }}
                </template>
              </p>
            </template>
            <template v-slot:item.customerPay="{ item }">
              <span v-if="item.customerPay" class="blue--text text--darken"
                >${{ item.customerPay }}</span
              >
            </template>
            <template v-slot:item.carrierPay="{ item }">
              <span v-if="item.carrierPay" class="blue--text text--darken"
                >${{ item.carrierPay }}</span
              >
            </template>
            <template v-slot:item.margin="{ item }">
              <span v-if="item.margin" class="blue--text text--darken"
                >${{ item.margin }}</span
              >
            </template>
            <template v-slot:footer>
              <v-page
                :total-row="totalRecord"
                :page-size-menu="[10, 20, 50, 100]"
                class="pt-3 pr-3 pb-3"
                @page-change="readDataFromAPI"
              />
            </template>
            <template v-slot:item.payment="{ item }">
              <p class="mb-0">
                <b>{{ item.paymentMethod }} - {{ item.paymentType }}</b>
              </p>
              <p v-if="item.checkId" class="mb-0">
                <b>Id: </b>
                <span>{{ item.checkId }}</span>
              </p>
            </template>
          </v-data-table>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Payments",
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: "Customer", value: "customerName", align: "center" },
        { text: "Load Number", value: "loadNumber", align: "center" },
        { text: "Status", value: "loadStatus", align: "center" },
        { text: "Pay To", value: "name", align: "center" },
        { text: "Origin", value: "loadShipper", align: "center" },
        {
          text: "Destination",
          value: "loadConsignee",
          align: "center",
        },
        {
          text: "Equipment Type",
          value: "loadEquipmentType",
          align: "center",
        },
        { text: "Customer Pay", value: "customerPay", align: "center" },
        { text: "Carrier Pay", value: "carrierPay", align: "center" },
        { text: "Margin", value: "margin", align: "center" },
        { text: "Invoice #", value: "invoiceNumber", align: "center" },
        {
          text: "Payment",
          value: "payment",
          align: "center",
        },
      ],
      payments: [],
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
      queryParams: {},
    }
  },
  computed: {
    ...mapGetters("carrier", {
      carrierPayments: "carrierPayments",
    }),
  },
  watch: {
    carrierPayments() {
      this.totalRecord = this.carrierPayments.count
      this.payments = this.carrierPayments.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.payments = []
    this.getCarrierPayment()
  },
  methods: {
    getCarrierPayment() {
      this.queryParams = {
        id: this.carrierId,
        params: {
          limit: 10,
          offset: 0,
        },
      }
      this.$store.dispatch("carrier/GET_CARRIER_PAYMENTS", this.queryParams)
    },
    readDataFromAPI(value) {
      if (this.payments.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.params.offset = pageNumber
        this.queryParams.params.limit = this.pageSize
        this.$store.dispatch("carrier/GET_CARRIER_PAYMENTS", this.queryParams)
      }
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split("-")
      return `${month}/${day}/${year.toString().substr(-2)}`
    },
  },
}
</script>
