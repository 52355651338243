<template>
  <v-container class="pt-1" fluid>
    <v-row>
      <v-col class="pt-0">
        <v-container
          id="tblShipments"
          class="pl-0 pr-0 pt-0"
          fluid
          style="overflow-y: auto; max-height: 550px; position: relative"
        >
          <v-data-table
            :headers="headers"
            :items="shipments"
            :items-per-page="pageSize"
            calculate-widths
            class="elevation-2 data-table"
            fixed-header
            hide-default-footer
            @click:row="showMatchedLoad"
          >
            <template v-slot:item.id="{ item }">
              <v-btn
                :to="{
                  name: 'Load Detail',
                  params: { id: item.id },
                }"
                small
                target="_blank"
                text
              >
                {{ item.id }}
              </v-btn>
            </template>
            <template v-slot:item.shipper="{ item }">
              <p v-if="item.shipper !== null">
                {{
                  formatLocation(
                    item.shipper.city,
                    item.shipper.state_province_region
                  )
                }}
                <br />
                <span class="subtitle">{{ item.pickupDate }}</span>
              </p>
            </template>
            <template v-slot:item.consignee="{ item }">
              <p v-if="item.consignee !== null">
                {{
                  formatLocation(
                    item.consignee.city,
                    item.consignee.state_province_region
                  )
                }}
                <br />
                <span class="subtitle">{{ item.deliveryDate }}</span>
              </p>
            </template>
            <template v-slot:item.loadStatus="{ item }">
              <load-status-button
                :agent-detail="agentDetail"
                :disable-click="true"
                :load-status-items="loadStatusChoices"
                :shipment-detail="item"
              />
            </template>
          </v-data-table>
          <v-page
            :page-size-menu="[10, 20, 50, 100]"
            :total-row="totalRecord"
            class="pt-3 pr-3"
            @page-change="readDataFromAPI"
          />
        </v-container>
      </v-col>
    </v-row>
    <related-loads :carrier-id="carrierId" />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
import common from "@/utils/common"
import LoadStatusButton from "@/views/pages/components/LoadStatusButton"
import RelatedLoads from "@/views/pages/carrier-profile/RelatedLoads"

export default {
  name: "MatchLoads",
  components: {
    LoadStatusButton,
    RelatedLoads,
  },
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: "Customer", value: "customerCompany", sortable: false },
        {
          text: "Load Number",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          value: "loadStatus",
          align: "center",
          sortable: false,
        },
        {
          text: "Origin",
          value: "shipper",
          align: "center",
          sortable: false,
        },
        {
          text: "Destination",
          value: "consignee",
          align: "center",
          sortable: false,
        },
        {
          text: "Equipment Type",
          value: "equipmentType",
          align: "center",
          sortable: false,
        },
        {
          text: "Customer Pay",
          value: "customerPrice",
          align: "right",
          sortable: false,
        },
        {
          text: "Carrier Pay",
          value: "carrierStartBuy",
          align: "right",
          sortable: false,
        },
        {
          text: "Load Count",
          value: "loadMatchCount",
          align: "center",
          sortable: false,
        },
      ],
      shipments: [],
      queryParams: {},
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
    }
  },
  computed: {
    ...mapGetters("carrierMatching", ["outboundLoads"]),
    ...mapGetters("loadDetail", ["loadStatusChoices", "loadStatusUpdate"]),
    ...mapGetters("generic", ["agentDetail"]),
  },
  watch: {
    outboundLoads() {
      this.totalRecord = this.outboundLoads.count
      this.numberOfPages = Math.ceil(this.outboundLoads.count / this.pageSize)
      this.shipments = this.outboundLoads.results
    },
    loadStatusUpdate() {
      if (this.loadStatusUpdate !== null) {
        const idx = this.shipments.findIndex(
          (x) => x.id === this.loadStatusUpdate.id
        )
        this.shipments[idx].loadStatus = this.loadStatusUpdate.loadStatusDisplay
      }
    },
  },
  mounted() {
    this.getOutboundLoad()
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    getOutboundLoad() {
      this.queryParams = {
        limit: this.pageSize,
        offset: 0,
        carrierId: this.carrierId,
      }
      this.$store.dispatch(
        "carrierMatching/GET_OUTBOUND_LOADS",
        this.queryParams
      )
    },
    showMatchedLoad(value) {
      this.$store.commit("carrierMatching/setMatchDialog", true)
      this.$store.commit("carrierMatching/setDialogLoad", value)
    },
    readDataFromAPI(value) {
      if (this.shipments.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch(
          "carrierMatching/GET_OUTBOUND_LOADS",
          this.queryParams
        )
      }
    },
  },
}
</script>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.load-number {
  cursor: pointer;
  text-decoration: none;
}
.load-number:hover {
  cursor: pointer;
  text-decoration: underline;
}
.v-btn:not(.v-btn--round).v-size--small {
  font-size: 12px;
}
div.v-pagination.v-pagination--right {
  bottom: -16px;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
