<template>
  <v-container class="pt-1" fluid>
    <v-row>
      <v-col :cols="columnSize" class="pt-0">
        <v-container
          id="tblShipments"
          class="pl-0 pr-0 pt-0 data-container"
          fluid
          style="overflow-y: auto; max-height: 550px"
        >
          <load-tracking />
          <v-data-table
            :headers="headers"
            :items="shipments"
            :items-per-page="pageSize"
            calculate-widths
            class="elevation-2 data-table"
            fixed-header
            hide-default-footer
          >
            <template v-slot:item.customerCompany="{ item }">
              <p @click="loadClick(item.id)">
                {{ item.customerCompany }}
              </p>
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                :to="{
                  name: 'Load Detail',
                  params: { id: item.id },
                }"
                small
                target="_blank"
                text
              >
                {{ item.id }}
              </v-btn>
            </template>
            <template v-slot:item.shipper="{ item }">
              <p v-if="item.shipper !== null">
                {{
                  formatLocation(
                    item.shipper.city,
                    item.shipper.state_province_region
                  )
                }}
                <br />
                <span class="subtitle">{{ item.pickupDate }}</span>
              </p>
            </template>
            <template v-slot:item.consignee="{ item }">
              <p v-if="item.consignee !== null">
                {{
                  formatLocation(
                    item.consignee.city,
                    item.consignee.state_province_region
                  )
                }}
                <br />
                <span class="subtitle">{{ item.deliveryDate }}</span>
              </p>
            </template>
            <template v-slot:item.loadStatus="{ item }">
              <load-status-button
                :agent-detail="agentDetail"
                :load-status-items="loadStatusChoices"
                :shipment-detail="item"
              />
            </template>
            <template v-slot:item.margin="{ item }">
              <p v-if="item.margin !== null">{{ item.margin }}%</p>
            </template>
            <template v-slot:item.carrierPrice="{ item }">
              <p v-if="item.carrierPrice !== null">${{ item.carrierPrice }}</p>
            </template>
            <template v-slot:item.customerPrice="{ item }">
              <p v-if="item.customerPrice !== null">
                ${{ item.customerPrice }}
              </p>
            </template>
            <template v-slot:footer>
              <v-page
                :page-size-menu="[10, 20, 50, 100]"
                :total-row="totalRecord"
                class="pt-3 pr-3 pb-3"
                @page-change="readDataFromAPI"
              />
            </template>
          </v-data-table>
        </v-container>
      </v-col>
      <v-col v-if="showDetail" cols="4">
        <v-card class="mt-0" light style="height: 730px; overflow-y: auto">
          <shipment-info />
          <v-container>
            <v-row>
              <v-col cols="12">
                <tender-detail :show-edit="false" />
                <v-container
                  class="mt-2"
                  style="border: 1px solid #cccccc; border-radius: 2px"
                >
                  <shipper-info :hgt-class="heightClass" :show-edit="false" />
                </v-container>
                <v-container
                  class="mt-2"
                  style="border: 1px solid #cccccc; border-radius: 2px"
                >
                  <consignee-info :hgt-class="heightClass" :show-edit="false" />
                </v-container>
                <v-container
                  class="mt-2"
                  style="border: 1px solid #cccccc; border-radius: 2px"
                >
                  <commodity-dimensions
                    :hgt-class="heightClass"
                    :show-edit="false"
                  />
                </v-container>
                <v-container
                  class="mt-2"
                  style="border: 1px solid #cccccc; border-radius: 2px"
                >
                  <carrier-info :hgt-class="heightClass" :show-edit="false" />
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
import common from "@/utils/common"
import TenderDetail from "@/views/pages/load-detail-components/TenderDetail"
import ShipperInfo from "@/views/pages/load-detail-components/ShipperInfo"
import ConsigneeInfo from "@/views/pages/load-detail-components/ConsigneeInfo"
import CommodityDimensions from "@/views/pages/load-detail-components/CommodityDimensions"
import CarrierInfo from "@/views/pages/load-detail-components/CarrierInfo"
import ShipmentInfo from "@/views/pages/load-detail-components/ShipmentInfo"
import LoadTracking from "@/views/pages/LoadTracking"
import LoadStatusButton from "@/views/pages/components/LoadStatusButton"
export default {
  name: "ProfileShipment",
  components: {
    TenderDetail,
    ShipperInfo,
    ConsigneeInfo,
    CommodityDimensions,
    CarrierInfo,
    ShipmentInfo,
    LoadTracking,
    LoadStatusButton,
  },
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      currentLoadNum: 0,
      showDetail: false,
      columnSize: 12,
      loadItems: [],
      headers: [
        { text: "Customer", value: "customerCompany", sortable: false },
        {
          text: "Load Number",
          value: "id",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          value: "loadStatus",
          align: "center",
          sortable: false,
        },
        {
          text: "Origin",
          value: "shipper",
          align: "center",
          sortable: false,
        },
        {
          text: "Destination",
          value: "consignee",
          align: "center",
          sortable: false,
        },
        {
          text: "Equipment Type",
          value: "equipmentType",
          sortable: false,
        },
        {
          text: "Customer Pay",
          value: "customerPrice",
          align: "right",
          sortable: false,
        },
        {
          text: "Carrier Pay",
          value: "carrierPrice",
          align: "right",
          sortable: false,
        },
        {
          text: "Margin",
          value: "margin",
          align: "right",
          sortable: false,
        },
      ],
      shipments: [],
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
      queryParams: {},
    }
  },
  computed: {
    ...mapGetters("carrier", ["carrierShipments"]),
    ...mapGetters("loadDetail", ["loadStatusChoices", "loadStatusUpdate"]),
    ...mapGetters("generic", ["agentDetail"]),
  },
  watch: {
    carrierShipments() {
      this.totalRecord = this.carrierShipments.count
      this.shipments = this.carrierShipments.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
    loadStatusUpdate() {
      if (this.loadStatusUpdate !== null) {
        const idx = this.shipments.findIndex(
          (x) => x.id === this.loadStatusUpdate.id
        )
        this.shipments[idx].loadStatus = this.loadStatusUpdate.loadStatusDisplay
      }
    },
  },
  mounted() {
    this.shipments = []
    this.getLoads()
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    getLoads() {
      this.shipments = []
      this.queryParams = {}
      this.queryParams.limit = this.pageSize
      this.queryParams.offset = 0
      this.queryParams.ordering = "-pickup_date"
      const payload = {
        carrierId: this.carrierId,
        queryParams: this.queryParams,
      }
      this.$store.dispatch("carrier/GET_CARRIER_SHIPMENTS", payload)
    },
    loadClick(value) {
      if (this.currentLoadNum !== value) {
        this.showDetail = true
        this.columnSize = 8
        this.currentLoadNum = value
        this.getLoadDetail(value)
      } else {
        this.showDetail = false
        this.columnSize = 12
        this.currentLoadNum = 0
      }
    },
    getLoadDetail(loadId) {
      this.$store.dispatch("loadDetail/SET_LOAD_ID", loadId)
      this.$store.dispatch("loadDetail/GET_LOAD_DETAIL", loadId)
    },
    readDataFromAPI(value) {
      if (this.shipments.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        const payload = {
          carrierId: this.carrierId,
          queryParams: this.queryParams,
        }
        this.$store.dispatch("carrier/GET_CARRIER_SHIPMENTS", payload)
      }
    },
  },
}
</script>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.load-number {
  cursor: pointer;
  text-decoration: none;
}
.load-number:hover {
  cursor: pointer;
  text-decoration: underline;
}
.v-btn:not(.v-btn--round).v-size--small {
  font-size: 12px;
}
</style>

<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
