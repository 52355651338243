<template>
  <div>
    <v-container class="pb-0 pt-0" fluid style="max-width: 1600px">
      <v-card>
        <div class="pa-2 header-banner">
          <v-row>
            <div class="col-md-9 d-flex align-center">
              <div
                class="
                  white
                  rounded-circle
                  d-inline-block
                  align-center
                  text-center
                  short-name
                "
              >
                <div
                  class="transition-swing font-weight-bold grey--text"
                  style="font-size: 48px !important"
                >
                  {{ shortCompanyName }}
                </div>
              </div>
              <div class="white--text ml-4 mt-4">
                <v-list-item-title class="mb-1" style="font-size: 24px">
                  {{ currentCarrier.name }}
                </v-list-item-title>
                <div style="font-size: 18px">
                  MC {{ currentCarrier.mcNumber }} - DOT
                  {{ currentCarrier.dotNumber }}
                </div>
                <div style="font-size: 18px">
                  {{ currentCarrier.city
                  }}<span v-if="currentCarrier.state">,</span>
                  {{ currentCarrier.state }}
                </div>
                <div class="carrier-indicators">
                  <span :class="indicators.paymentMethods.class">
                    {{ indicators.paymentMethods.text }}
                  </span>
                </div>
                <v-switch v-model="currentStatus" color="secondary">
                  <template v-slot:label>
                    <span class="white--text text-h4"
                      >Carrier Status:
                      {{ currentStatus ? "Active" : "Inactive" }}</span
                    >
                  </template>
                </v-switch>
              </div>
            </div>
            <div
              v-if="currentCarrier.carrierManager"
              :style="$vuetify.breakpoint.mdAndUp ? '' : 'display:none;'"
              class="col-md-3"
            >
              <v-list-item-title
                class="mb-1"
                style="font-size: 24px; color: #ffffff; text-align: right"
              >
                <b>Manager:</b> {{ currentCarrier.carrierManager.name }}
              </v-list-item-title>
            </div>
          </v-row>
        </div>
        <div>
          <v-tabs v-model="currentTab" centered>
            <v-tab href="#tab-1">Summary</v-tab>
            <v-tab href="#tab-2">Details</v-tab>
            <v-tab href="#tab-8">Matches</v-tab>
            <v-tab href="#tab-9">Quotes</v-tab>
            <v-tab href="#tab-3">Shipments</v-tab>
            <v-tab href="#tab-10">Payments</v-tab>
            <v-tab href="#tab-11">Payment Methods</v-tab>
            <v-tab href="#tab-4">Documents</v-tab>
            <v-tab href="#tab-5">Lanes</v-tab>
            <v-tab href="#tab-6">Equipment</v-tab>
            <v-tab href="#tab-7">Notes</v-tab>
          </v-tabs>
        </div>
        <v-progress-linear :active="loadingIcon" color="amber" indeterminate />
      </v-card>
    </v-container>
    <v-container class="pt-0 px-0" fluid style="max-width: 1600px">
      <v-tabs-items v-model="currentTab" style="background: none">
        <v-tab-item value="tab-1">
          <carrier-summary :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-2">
          <profile-detail :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-3">
          <profile-shipment ref="shipmentTab" :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-4">
          <documents :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-5">
          <lanes :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-6">
          <equipment :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-7">
          <notes :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-8">
          <match-loads ref="matchLoadTab" :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-9">
          <quotes ref="quoteTab" :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-10">
          <carrier-payments ref="paymentTab" :carrier-id="id" />
        </v-tab-item>
        <v-tab-item value="tab-11">
          <payment-methods :carrier-id="id" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CarrierSummary from "@/views/pages/carrier-profile/Summary"
import ProfileDetail from "@/views/pages/carrier-profile/ProfileDetail"
import ProfileShipment from "@/views/pages/carrier-profile/Shipment"
import Documents from "@/views/pages/carrier-profile/Documents"
import Lanes from "@/views/pages/carrier-profile/Lanes"
import Equipment from "@/views/pages/carrier-profile/Equipment"
import Notes from "@/views/pages/carrier-profile/Notes"
import MatchLoads from "@/views/pages/carrier-profile/MatchLoads"
import Quotes from "@/views/pages/carrier-profile/Quotes"
import CarrierPayments from "@/views/pages/carrier-profile/Payments"
import PaymentMethods from "@/views/pages/carrier-profile/payment-methods"
import common from "@/utils/common"

export default {
  name: "CarrierDetail",
  components: {
    CarrierSummary,
    ProfileDetail,
    ProfileShipment,
    Documents,
    Lanes,
    Equipment,
    Notes,
    MatchLoads,
    Quotes,
    CarrierPayments,
    PaymentMethods,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      currentTab: "tab-1",
      carrierName: "",
      currentStatus: null,
    }
  },
  computed: {
    ...mapGetters("carrier", ["currentCarrier", "selectedTab"]),
    ...mapGetters("generic", ["loadingIcon"]),
    shortCompanyName() {
      let shortName = ""
      if (this.currentCarrier.name !== undefined) {
        shortName = this.currentCarrier.name.match(/\b(\w)/g).join("")
        shortName = shortName.charAt(0) + shortName.charAt(1)
      }
      return shortName
    },
    tabIndex() {
      return this.currentTab.split("-").pop()
    },
    indicators() {
      return {
        paymentMethods: {
          text: `${this.currentCarrier.hasPrimaryPaymentMethod ? 'Has' : 'No'} primary payment method`,
          class: this.currentCarrier.hasPrimaryPaymentMethod ? 'indicator-success' : 'indicator-warning',
        },
      }
    }
  },
  watch: {
    selectedTab() {
      this.currentTab = this.selectedTab
    },
    currentCarrier() {
      common.methods.setPageTitle(
        this.currentCarrier.name + " - " + this.currentCarrier.mcNumber
      )
      this.currentStatus = !this.currentCarrier.blacklist
    },
    currentStatus(newVal, oldVal) {
      if (oldVal !== null) this.updateCarrierProfile()
    },
    tabIndex(index) {
      if (index === this.$route.query.tab) return

      if (this.$route.query.tab === undefined) {
        this.$router.replace({ query: { tab: index }})
      } else {
        this.$router.push({ query: { tab: index }})
      }

      this.changeTab(index)
    },
    '$route.query.tab'() {
      this.updateCurrentTabFromQueryParam()
    }
  },
  mounted() {
    this.$store.commit("carrier/setCarrierId", this.id)
    this.$store.dispatch("carrier/GET_CARRIER_COMPANY", this.id)
    this.updateCurrentTabFromQueryParam()
  },
  methods: {
    updateCurrentTabFromQueryParam() {
      if (this.$route.query.tab !== undefined && this.$route.query.tab !== this.tabIndex) {
        this.currentTab = `tab-${this.$route.query.tab}`
      }
    },
    updateCarrierProfile() {
      const payload = {
        id: this.currentCarrier.id,
        carrier: {
          blacklist: !this.currentStatus,
          ein: this.currentCarrier.ein,
          name: this.currentCarrier.name,
          mc_number: this.currentCarrier.mcNumber,
          dot_number: this.currentCarrier.dotNumber,
          scac_number: this.currentCarrier.scacNumber,
          phone: this.currentCarrier.phone,
          phone_two: this.currentCarrier.phoneTwo,
          phone_three: this.currentCarrier.phoneThree,
          fax: this.currentCarrier.fax,
          email: this.currentCarrier.email,
          send_offers: this.currentCarrier.sendOffers,
          days_between_emails: this.currentCarrier.daysBetweenEmails,
        },
      }
      this.$store.dispatch("carrier/UPDATE_CARRIER_COMPANY", payload)
    },
    changeTab(index) {
      if (index === "3") {
        if (this.$refs.shipmentTab !== undefined) {
          this.$refs.shipmentTab.getLoads()
        }
      } else if (index === "8") {
        if (this.$refs.matchLoadTab !== undefined) {
          this.$refs.matchLoadTab.getOutboundLoad()
        }
      } else if (index === "10") {
        if (this.$refs.paymentTab !== undefined) {
          this.$refs.paymentTab.getCarrierPayment()
        }
      } else if (index === "9") {
        if (this.$refs.quoteTab !== undefined) {
          this.$refs.quoteTab.getQuotes()
        }
      }
    },
  },
}
</script>

<style scoped>
.header-banner {
  background: linear-gradient(
      0deg,
      rgba(30, 136, 229, 0.72),
      rgba(30, 136, 229, 0.72)
    ),
    #fdfdfd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px 6px 0px 0px;
}
.short-name {
  border: 2px solid rgb(30, 136, 229) !important;
  width: 127px;
  height: 127px;
  line-height: 127px;
}

.carrier-indicators {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.carrier-indicators > * {
  border-radius: 20px;
  padding: 2px 8px;
  border: 1px solid #787878;
}

.carrier-indicators .indicator-success {
  background: #4caf50;
}
.carrier-indicators .indicator-warning {
  background: darkorange;
}
</style>
