<template>
  <v-container fluid class="pt-1">
    <v-row>
      <v-col class="pt-0">
        <v-container
          id="tblQuotes"
          fluid
          class="pl-0 pr-0 pt-0 data-container"
          style="overflow-y: auto; max-height: 550px"
        >
          <v-data-table
            id="tblData"
            :headers="headers"
            :items="quotes"
            class="elevation-2 data-table"
            calculate-widths
            hide-default-footer
            fixed-header
            :items-per-page="pageSize"
          >
            <template v-slot:item.load="{ item }">
              <v-btn
                text
                small
                target="_blank"
                :to="{
                  name: 'Load Detail',
                  params: { id: item.load.id },
                }"
              >
                {{ item.load.id }}
              </v-btn>
            </template>
            <template v-slot:item.shipper="{ item }">
              <span v-if="item.shipper">{{
                formatLocation(
                  item.shipper.city,
                  item.shipper.state_province_region
                )
              }}</span>
            </template>
            <template v-slot:item.consignee="{ item }">
              <span v-if="item.consignee">{{
                formatLocation(
                  item.consignee.city,
                  item.consignee.state_province_region
                )
              }}</span>
            </template>
            <template v-slot:item.name="{ item }">
              <router-link
                v-if="item.carrierCompany"
                :to="{
                  name: 'Carrier Detail',
                  params: { id: item.carrierCompany },
                }"
                class="name-link"
                target="_blank"
              >
                <span>{{ item.name }}</span>
              </router-link>
              <span v-else
                >{{ item.name }}
                <v-icon x-small color="red">mdi-bus-alert</v-icon></span
              >
            </template>
            <template v-slot:footer>
              <v-page
                :total-row="totalRecord"
                :page-size-menu="[10, 20, 50, 100]"
                class="pt-3 pr-3 pb-3"
                @page-change="readDataFromAPI"
              />
            </template>
          </v-data-table>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import common from "@/utils/common"
export default {
  name: "Quotes",
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: "Load #", value: "load", align: "center" },
        { text: "Origin", value: "shipper", align: "center" },
        { text: "Destination", value: "consignee", align: "center" },
        {
          text: "Equipment Type",
          value: "equipmentType",
          align: "center",
        },
        { text: "Phone", value: "phone", align: "center" },
        { text: "Email", value: "email", align: "center" },
        { text: "Date", value: "createdAt", align: "center" },
        { text: "Bid Amount", value: "carrierBid", align: "right" },
      ],
      quotes: [],
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
      queryParams: {},
    }
  },
  computed: {
    ...mapGetters("carrierMatching", {
      quoteListByCarrier: "quoteListByCarrier",
    }),
  },
  watch: {
    quoteListByCarrier() {
      this.totalRecord = this.quoteListByCarrier.count
      this.quotes = this.quoteListByCarrier.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.quotes = []
    this.getQuotes()
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    getQuotes() {
      this.queryParams = {
        carrierId: this.carrierId,
        limit: 10,
        offset: 0,
      }
      this.$store.dispatch(
        "carrierMatching/GET_QUOTES_BY_CARRIER",
        this.queryParams
      )
    },
    readDataFromAPI(value) {
      if (this.quotes.length > 0) {
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch(
          "carrierMatching/GET_QUOTES_BY_CARRIER",
          this.queryParams
        )
      }
    },
  },
}
</script>
