<template>
  <v-form ref="form" v-model="isValid">
    <v-container>
      <v-row>
        <v-col class="pt-0 pb-0" :cols="paymentMethodChoice === 'FACTORING' ? 6 : 12">
          <v-text-field
            v-if="paymentMethodChoice === 'CHECK'"
            :value="paymentMethod.businessName"
            @input="setPaymentMethodField('businessName', $event)"
            :rules="[rules.required, rules.alphaNumeric]"
            color="info"
            label="Pay to the order of"
          />
          <factoring-auto-complete
            v-else
            :factoringCompanyName="paymentMethod.businessName"
            @factoringCompanySelect="selectFactoringCompany"
          />
        </v-col>
        <v-col class="pt-0 pb-0" :cols="6" v-if="paymentMethodChoice === 'FACTORING'">
          <v-text-field
            :value="paymentMethod.contactName"
            @input="setPaymentMethodField('contactName', $event)"
            :rules="[rules.alphaNumeric]"
            color="info"
            label="Contact Name"
          />
        </v-col>
      </v-row>
      <v-row v-if="paymentMethodChoice === 'FACTORING'">
        <v-col class="pt-0 pb-0" :cols="6">
          <v-text-field
            :value="paymentMethod.email"
            @input="setPaymentMethodField('Email', $event)"
            :rules="[rules.email]"
            color="info"
            label="Email"
          />
        </v-col>
        <v-col class="pt-0 pb-0" :cols="6">
          <v-text-field
            :value="paymentMethod.phone"
            @input="setPaymentMethodField('Phone', $event)"
            :rules="[rules.phone]"
            color="info"
            label="Phone"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0" cols="6">
          <v-text-field
            :value="paymentMethod.addressLine1"
            @input="setPaymentMethodField('addressLine1', $event)"
            :rules="[rules.required, rules.alphaNumeric]"
            color="info"
            label="Street address"
          />
        </v-col>
        <v-col class="pt-0 pb-0" cols="6">
          <v-text-field
            :value="paymentMethod.apt"
            @input="setPaymentMethodField('apt', $event)"
            :rules="[rules.alphaNumeric]"
            color="info"
            label="Apt/Unit/Suite"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0" cols="6">
          <v-select
            :value="paymentMethod.country"
            @change="setPaymentMethodField('country', $event)"
            :rules="[rules.required]"
            :items="countries"
            item-text="name"
            item-value="id"
            color="info"
            label="Country"
          />
        </v-col>
        <v-col class="pt-0 pb-0" cols="6">
          <v-text-field
            :value="paymentMethod.city"
            @input="setPaymentMethodField('city', $event)"
            :rules="[rules.required, rules.name]"
            color="info"
            label="City"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0" cols="6">
          <v-text-field
            :value="paymentMethod.state"
            @input="setPaymentMethodField('state', $event)"
            :rules="[rules.required, rules.name]"
            color="info"
            label="State"
          />
        </v-col>
        <v-col class="pt-0 pb-0" cols="6">
          <v-text-field
            :value="paymentMethod.postalCode"
            @input="setPaymentMethodField('postalCode', $event)"
            :rules="[rules.required, rules.postalCode]"
            color="info"
            label="Postal code"
            hide-spin-buttons
            validate-on-blur
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import validation from "@/utils/form-validation"
import { mapGetters } from "vuex"
import FactoringAutoComplete from '../../components/FactoringAutoComplete.vue'


export default {
  name: "CreatePaymentMethodCheckForm",
  components: {
    FactoringAutoComplete
  },
  props: {
    paymentMethodChoice: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isValid: false,
      rules: validation.rules,
      factoringCompany: null
    }
  },
  watch: {
    isValid(value) {
      this.$store.commit(this.validGetter, value)
    }
  }, 
  computed: {
    ...mapGetters("generic", ["countries"]),
    ...mapGetters("carrier", ["paymentMethod"]),
    validGetter() {
      return this.paymentMethodChoice === "CHECK" ? "carrier/setCheckFormIsValid" : "carrier/setFactoringFormIsValid"
    },
    setFormFieldMutation() {
      return this.paymentMethodChoice === "CHECK" ? "carrier/setCheckFormField" : "carrier/setFactoringFormField"
    }
  },
  methods: {
    setPaymentMethodField(field, value){
      this.$store.commit(this.setFormFieldMutation, { field, value })
    },
    selectFactoringCompany(value) {
      this.$store.commit(this.setFormFieldMutation, { field: "businessName", value: value.businessName })
      this.$store.commit(this.setFormFieldMutation, { field: "addressLine1", value: value.addressLine1 })
      this.$store.commit(this.setFormFieldMutation, { field: "apt", value: value.addressLine2 })
      this.$store.commit(this.setFormFieldMutation, { field: "city", value: value.city })
      this.$store.commit(this.setFormFieldMutation, { field: "state", value: value.state })
      this.$store.commit(this.setFormFieldMutation, { field: "postalCode", value: value.postalCode })
      this.$store.commit(this.setFormFieldMutation, { field: "country", value: value.country })
      this.$store.commit(this.setFormFieldMutation, { field: "email", value: value.email })
      this.$store.commit(this.setFormFieldMutation, { field: "phone", value: value.phone })
      this.$store.commit(this.setFormFieldMutation, { field: "contactName", value: value.contactName })
    },
  },
}
</script>

<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}
.v-textarea /deep/ .v-text-field__slot textarea {
  resize: none;
}
</style>
