<template>
  <v-container fluid>
    <v-card>
      <v-container>
        <v-row>
          <div class="col-md-12 blue--text text--lighten-2 font-size-14">
            <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
              mdi-highway
            </v-icon>
            Equipment
          </div>
        </v-row>
        <v-row>
          <v-col cols="12" md="2" sm="3">
            <v-checkbox
              v-model="currentCarrier.equipmentTypes.wreckerService"
              label="Wrecker Service"
            />
          </v-col>
          <v-col cols="12" md="2" sm="3">
            <v-checkbox
              v-model="currentCarrier.equipmentTypes.rgn"
              label="RGN"
            />
          </v-col>
          <v-col cols="12" md="2" sm="3">
            <v-checkbox
              v-model="currentCarrier.equipmentTypes.van"
              label="VAN"
            />
          </v-col>
          <v-col cols="12" md="2" sm="3">
            <v-checkbox
              v-model="currentCarrier.equipmentTypes.powerOnly"
              label="Power Only"
            />
          </v-col>
          <v-col cols="12" md="2" sm="3">
            <v-checkbox
              v-model="currentCarrier.equipmentTypes.stepDeck"
              label="Step Deck"
            />
          </v-col>
          <v-col cols="12" md="2" sm="3">
            <v-checkbox
              v-model="currentCarrier.equipmentTypes.flatbed"
              label="Flatbed"
            />
          </v-col>
          <v-col cols="12" md="2" sm="3">
            <v-checkbox
              v-model="currentCarrier.equipmentTypes.reefer"
              label="Reefer"
            />
          </v-col>
          <v-col cols="12" md="2" sm="3">
            <v-checkbox
              v-model="currentCarrier.equipmentTypes.hotshot"
              label="Hotshot"
            />
          </v-col>
          <v-col cols="12" md="2" sm="3">
            <v-checkbox
              v-model="currentCarrier.equipmentTypes.specialized"
              label="Specialized"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2" class="offset-md-10">
            <v-btn
              class="blue white--text font-weight-bold"
              @click="updateEquipment"
            >
              Update Equipment
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "Equipment",
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    ...mapGetters("carrier", {
      currentCarrier: "currentCarrier",
    }),
  },
  mounted() {
    this.getEquipments()
  },
  methods: {
    getEquipments() {
      this.$store.dispatch("carrier/GET_CARRIER_COMPANY", this.carrierId)
    },
    updateEquipment() {
      let payload = {}
      if (this.currentCarrier.id) {
        payload = {
          id: this.currentCarrier.id,
          carrier: {
            name: this.currentCarrier.name,
            mc_number: this.currentCarrier.mcNumber,
            dot_number: this.currentCarrier.dotNumber,
            phone: this.currentCarrier.phone,
            phone_two: this.currentCarrier.phoneTwo,
            phone_three: this.currentCarrier.phoneThree,
            fax: this.currentCarrier.fax,
            email: this.currentCarrier.email,
            address: this.currentCarrier.address,
            city: this.currentCarrier.city,
            state: this.currentCarrier.state,
            us_zipcode: this.currentCarrier.usZipcode,
            ca_province: this.currentCarrier.caProvince,
            ca_postal_code: this.currentCarrier.caPostalCode,
            country: this.currentCarrier.country,
            wrecker_service: this.currentCarrier.wreckerService,
            rgn: this.currentCarrier.equipmentTypes.rgn,
            van: this.currentCarrier.equipmentTypes.van,
            power_only: this.currentCarrier.equipmentTypes.powerOnly,
            step_deck: this.currentCarrier.equipmentTypes.stepDeck,
            flatbed: this.currentCarrier.equipmentTypes.flatbed,
            reefer: this.currentCarrier.equipmentTypes.reefer,
            hotshot: this.currentCarrier.equipmentTypes.hotshot,
            specialized: this.currentCarrier.equipmentTypes.specialized,
          },
        }
        this.$store.dispatch("carrier/UPDATE_CARRIER_COMPANY", payload)
      }
    },
  },
}
</script>
