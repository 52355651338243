<template>
  <v-form ref="form" v-model="isValid">
    <v-container>
      <v-row>
        <v-col class="pb-0" cols="6">
          <v-select
            :value="paymentMethod.accountType"
            @change="setPaymentMethodField('accountType', $event)"
            :rules="[rules.required]"
            class="pt-0"
            color="info"
            label="Account type"
            :items="['CHECKING', 'SAVINGS']"
          />
        </v-col>
        <v-col class="pb-0" cols="6">
          <v-text-field
            :value="paymentMethod.routingNumber"
            @input="setPaymentMethodField('routingNumber', $event)"
            :rules="[rules.required, validateRoutingNumber]"
            class="pt-0"
            color="info"
            label="Roting (ABA) number"
            type="number"
            :counter="9"
            hide-spin-buttons
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0" cols="6">
          <v-text-field
            :value="paymentMethod.accountNumber"
            @input="setPaymentMethodField('accountNumber', $event)"
            :rules="[rules.required, validateAccountNumber]"
            class="pt-0"
            color="info"
            label="Account number"
            type="number"
            hide-spin-buttons
            validate-on-blur
          />
        </v-col>
        <v-col class="pt-0 pb-0" cols="6">
          <v-text-field
            :rules="[rules.required, validateAccountNumber, validateSameAccountNumber]"
            class="pt-0"
            color="info"
            label="Confirm account number"
            type="number"
            hide-spin-buttons
            validate-on-blur
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import validation from "@/utils/form-validation"
import { mapGetters } from "vuex"

export default {
  name: "CreatePaymentMethodACHForm",
  data() {
    return {
      isValid: false,
      rules: validation.rules,
    }
  },
  watch: {
    isValid(value) {
      this.$store.commit("carrier/setACHFormIsValid", value)
    }
  }, 
  computed: {
    ...mapGetters("carrier", ["paymentMethod"]),
  },
  methods: {
    validateRoutingNumber(value){
      if(typeof value === 'undefined') return true

      if (value.length < 9)
        return 'Routing number must be 9 digits'
      
      if (!/^[012346789][0-9]{8}$/.test(value))
        return 'Routing number is invalid'

      return true
    },
    validateAccountNumber(value){
      if(typeof value === 'undefined') return true

      const reg = /^\d{5,17}$/
      return reg.test(value) || 'Account number is invalid'
    },
    validateSameAccountNumber(value){
      if(typeof value === 'undefined') return true

      return value === this.paymentMethod?.accountNumber || 'Account numbers do not match'
    },
    setPaymentMethodField(field, value){
      this.$store.commit("carrier/setACHFormField", { field, value })
    }
  },
}
</script>

<style scoped>
.box-header {
  font-size: 14px;
  font-weight: 500;
}
.v-textarea /deep/ .v-text-field__slot textarea {
  resize: none;
}
</style>
