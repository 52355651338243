<template>
  <v-container class="pt-1" fluid>
    <v-row v-if="profile">
      <v-col
        v-if="hasAccess && profile.approved === 'No'"
        class="pt-0 pb-0 offset-md-8"
        cols="12"
        md="2"
      >
        <v-btn
          class="float-right"
          color="green"
          height="50"
          @click="approveCarrier()"
        >
          Approve Carrier
        </v-btn>
      </v-col>
      <div class="col-md-12 pt-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="col-md-12 blue--text text--lighten-2 font-size-14">
                <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
                  mdi-account-circle
                </v-icon>
                Profile
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="profile !== null">
              <v-row>
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="pt-0 pb-0 mt-n2"
                  cols="10"
                >
                  &nbsp;
                </v-col>
                <v-col class="pt-0 pb-0 mt-n2" cols="12" md="2">
                  <v-btn
                    class="float-right"
                    color="info"
                    text
                    @click="updateProfile()"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
              <v-form ref="profile-form" v-model="valid">
                <v-row>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.name"
                      :disabled="!hasAccess"
                      :rules="[rules.required, rules.alphaNumeric]"
                      label="Name"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.dotNumber"
                      :counter="9"
                      :disabled="!hasAccess"
                      :rules="[rules.required, rules.intDigit]"
                      label="DOT"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="profile.mcNumber"
                      :counter="10"
                      :disabled="!hasAccess"
                      :rules="[rules.intDigit]"
                      label="MC"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="profile.scacNumber"
                      :disabled="!hasAccess"
                      :rules="[rules.alphaNumeric()]"
                      label="SCAC"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="profile.ein"
                      :disabled="!hasAccess"
                      :rules="[rules.required, rules.intDigit]"
                      label="EIN"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.email"
                      :rules="[rules.email]"
                      label="Email"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.phone"
                      :rules="[rules.required, rules.phone]"
                      label="Phone 1"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="profile.phoneTwo"
                      :rules="[rules.phone]"
                      label="Phone 2"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="profile.phoneThree"
                      :rules="[rules.phone]"
                      label="Phone 3"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="profile.fax"
                      :rules="[rules.phone]"
                      label="Fax"
                      type="text"
                    />
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="3" lg="3" md="3" sm="3" xl="3">
                    <v-checkbox
                      v-model="profile.sendOffers"
                      label="Send Offers"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.daysBetweenEmails"
                      :rules="[rules.intDigit]"
                      label="Days Between Emails"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                      <carrier-sales-rep-auto-complete
                          data-cy="carrier-manager"
                          @event="selectedCarrierManager"
                          :default-carrier-sales-rep="profile.carrierManager"

                      />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="col-md-12 blue--text text--lighten-2 font-size-14">
                <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
                  mdi-account
                </v-icon>
                Account Receivable
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="profile !== null">
              <v-row>
                <v-col class="pt-2 pb-0" cols="6" lg="7" md="4">
                  <v-checkbox
                    v-model="profile.quickPay"
                    class="mt-1"
                    label="Quick Pay"
                  />
                </v-col>
                <v-col class="pt-2 pb-0" cols="6" md="2">
                  <v-btn
                    class="float-right"
                    color="info"
                    text
                    @click="updateARDetail()"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
              <v-form ref="carrier-ar-form" v-model="arValid">
                <v-row>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.arName"
                      :rules="[rules.required, rules.alphaNumeric]"
                      label="Name"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <detail-location-auto-complete
                      :address="profile.fullARAddress"
                      :country-code="countryCodes"
                      @event="setARLocation"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.arPhone"
                      :rules="[rules.required, rules.phone]"
                      label="Phone"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.arEmail"
                      :rules="[rules.required, rules.email]"
                      label="Email"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.arRemitEmail"
                      :rules="[rules.email]"
                      label="Remit Email"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.arContactName"
                      :rules="[rules.alphaNumeric]"
                      label="Contact Name"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.payTerms"
                      :rules="[rules.intDigit]"
                      label="Pay Terms"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="12"
                    xl="3"
                  >
                    <v-text-field
                      v-model="profile.quickbooksId"
                      :rules="[rules.intDigit]"
                      label="Quickbooks ID"
                      type="text"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="col-md-12 blue--text text--lighten-2 font-size-14">
                <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
                  mdi-map-marker
                </v-icon>
                Address
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="profile !== null">
              <v-row>
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="pt-0 pb-0 mt-n2"
                  cols="10"
                >
                  &nbsp;
                </v-col>
                <v-col class="pt-0 pb-0 mt-n2" cols="12" md="2">
                  <v-btn
                    class="float-right"
                    color="info"
                    text
                    @click="updateAddressDetail()"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
              <v-form ref="carrier-address-form" v-model="addressValid">
                <v-row>
                  <v-col
                    class="pt-0 pb-0 pl-12"
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                    xl="4"
                  >
                    <detail-location-auto-complete
                      :address="profile.fullAddress"
                      :country-code="countryCodes"
                      :disabled="!hasAccess"
                      @event="setLocation"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="col-md-9 blue--text text--lighten-2 font-size-14">
                <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
                  mdi-account-box-outline
                </v-icon>
                Contact
              </div>
              <div class="col-sm-10 col-md-6 col-lg-6 col-xl-6">
                <v-btn
                  class="font-weight-bold float-right"
                  small
                  style="color: #1e88e5"
                  text
                  @click.stop="addContact"
                >
                  <v-icon class="pl-3 pr-1" medium>
                    mdi-plus-circle-outline
                  </v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Add Contact</span>
                </v-btn>
                <v-btn
                  class="font-weight-bold float-right"
                  small
                  style="color: #1e88e5"
                  text
                  @click.stop="saveContact"
                >
                  <v-icon class="pl-3 pr-1" medium>
                    mdi-content-save-all
                  </v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Save All</span>
                </v-btn>
              </div>
            </v-expansion-panel-header>

            <template v-for="(item, key) in contacts">
              <v-expansion-panel-content :key="key" fluid>
                <v-row class="px-4">
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                    xl="4"
                  >
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                    xl="4"
                  >
                    <v-text-field
                      v-model="item.phone"
                      label="Phone"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                    xl="4"
                  >
                    <v-text-field
                      v-model="item.email"
                      label="Email"
                      type="text"
                    />
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="3" lg="2" md="2" sm="3" xl="2">
                    <v-checkbox v-model="item.isPrimary" label="Primary" />
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="3" lg="2" md="2" sm="3" xl="2">
                    <v-checkbox v-model="item.sendOffers" label="Send Offer" />
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="3" lg="2" md="2" sm="3" xl="2">
                    <v-checkbox v-model="item.isDispatch" label="Dispatch" />
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="3" lg="2" md="2" sm="3" xl="2">
                    <v-checkbox
                      v-model="item.isAccounting"
                      label="Accounting"
                    />
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="3" lg="2" md="2" sm="3" xl="2">
                    <v-checkbox v-model="item.isClaims" label="Claims" />
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="3" lg="2" md="2" sm="3" xl="2">
                    <v-checkbox v-model="item.isDriver" label="Driver" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-spacer />
                  <v-col class="text-right" cols="4" lg="4" sm="4" xl="4">
                    <v-btn
                      class="blue--text text--lighten-2 font-weight-bold mt-4"
                      small
                      text
                      @click="saveSingleContact(key)"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      class="red--text white--text font-weight-bold mt-4"
                      small
                      text
                      @click="removeContact(key)"
                    >
                      Remove
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider v-if="contacts.length > 1" />
              </v-expansion-panel-content>
            </template>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="col-md-9 blue--text text--lighten-2 font-size-14">
                <v-icon class="pl-3 pr-1 blue--text text--lighten-2" medium>
                  mdi-hazard-lights
                </v-icon>
                Insurance
              </div>
              <div class="col-sm-10 col-md-6 col-lg-6 col-xl-6">
                <v-btn
                  class="font-weight-bold float-right"
                  small
                  style="color: #1e88e5"
                  text
                  @click.stop="addInsurance"
                >
                  <v-icon class="pl-3 pr-1" medium>
                    mdi-plus-circle-outline
                  </v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Add Insurance</span>
                </v-btn>
                <v-btn
                  class="font-weight-bold float-right"
                  small
                  style="color: #1e88e5"
                  text
                  @click.stop="saveInsurance"
                >
                  <v-icon class="pl-3 pr-1" medium>
                    mdi-content-save-all
                  </v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp">Save All</span>
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <template v-for="(insurance, key) in insurances">
              <v-expansion-panel-content :key="key" fluid>
                <v-row>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-select
                      v-model="insurance.insuranceType"
                      :disabled="!hasAccess"
                      :items="insuranceType"
                      item-text="name"
                      item-value="id"
                      label="Insurance Type"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="insurance.coverageAmount"
                      :disabled="!hasAccess"
                      label="Amount"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-menu
                      v-model="insurance.expirationDateMenu"
                      :close-on-content-click="false"
                      :disabled="!hasAccess"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="insurance.expirationDate"
                          :disabled="!hasAccess"
                          label="Expiration"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="insurance.expirationDate"
                        no-title
                        @input="insurance.expirationDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="insurance.provider"
                      :disabled="!hasAccess"
                      label="Provider"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="insurance.producer"
                      :disabled="!hasAccess"
                      label="Producer"
                      type="text"
                    />
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-text-field
                      v-model="insurance.policyNumber"
                      :disabled="!hasAccess"
                      label="Policy Number"
                      type="text"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="insurance.notes"
                      :disabled="!hasAccess"
                      label="Notes"
                      type="text"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col class="text-right" cols="12" lg="2" md="2" sm="2">
                    <v-btn
                      class="font-weight-bold mt-4"
                      small
                      style="color: #1e88e5"
                      text
                      @click="saveSingleInsurance(key)"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      class="red--text white--text font-weight-bold mt-4 ml-2"
                      small
                      text
                      @click="removeInsurance(key)"
                    >
                      Remove
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider v-if="insurances.length > 1" />
              </v-expansion-panel-content>
            </template>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-text> Please confirm {{ removeItem }} deletion </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn color="grey darken-1" small text @click="dialog = false">
              Cancel
            </v-btn>

            <v-btn
              color="orange darken-1"
              small
              text
              @click="removeRecord(index)"
            >
              Delete {{ removeItem }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import validation from "@/utils/form-validation"
import moment from "moment"
import DetailLocationAutoComplete from "@/views/pages/components/DetailLocationAutoComplete"
import CarrierSalesRepAutoComplete from '@/views/pages/components/CarrierSalesRepAutoComplete'

export default {
  name: "ProfileDetail",
  components: {
    DetailLocationAutoComplete,
    CarrierSalesRepAutoComplete,
  },
  props: {
    carrierId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      arValid: false,
      addressValid: false,
      valid: false,
      search: null,
      dialog: false,
      rules: validation.rules,
      removeItem: "",
      index: 0,
      profile: null,
      contacts: [
        {
          id: 0,
          createdAt: null,
          updatedAt: null,
          name: "",
          phone: "",
          email: "",
          isPrimary: false,
          sendOffers: false,
          isDispatch: false,
          isAccounting: false,
          isClaims: false,
          isDriver: false,
        },
      ],
      insurances: [
        {
          id: 0,
          createdAt: null,
          updatedAt: null,
          coverageAmount: 0,
          expirationDate: null,
          provider: "",
          producer: "",
          policyNumber: "",
          notes: "",
          insuranceType: 0,
          insuranceTypeDisplay: "",
        },
      ],
      items: [
        {
          id: 1,
          text: "Active",
        },
        {
          id: 2,
          text: "Inactive",
        },
      ],
      quickPayValue: false,
    }
  },
  computed: {
    ...mapGetters("carrier", [
      "currentCarrier",
      "carrierInsurances",
      "carrierContacts",
      "insuranceType",
    ]),
    ...mapGetters("generic", ["agentDetail", "countries"]),
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    countryCodes() {
      return this.countries
        .map((country) => (country.id === "MX" ? "MEX" : country.id))
        .join(",")
    },
    loginUser() {
      return this.agentDetail.user || {}
    },
    hasAccess() {
      return this.agentDetail.carrierRelations || false
    },
  },
  watch: {
    currentCarrier() {
      this.profile = this.currentCarrier
      this.quickPayValue = this.currentCarrier.quickPay
      this.profile.fullAddress = {
        zipcode: this.profile.usZipcode || this.profile.caPostalCode,
        address: this.profile.address,
        city: this.profile.city,
        state: this.profile.state || this.profile.caProvince,
        country: this.profile.country,
      }
      if (this.currentCarrier.carrierManager !== null) {
        this.profile.carrierManager = {
          id: this.currentCarrier.carrierManager.id,
          name: this.currentCarrier.carrierManager.name,
          text: this.currentCarrier.carrierManager.name,
        }
      }
        this.profile.fullARAddress = {
            zipcode: this.profile.arZipcode,
            address: this.profile.arAddress,
            city: this.profile.arCity,
            state: this.profile.arState,
            country: this.profile.arCountry,
        }
    },
    carrierContacts() {
      this.contacts = this.carrierContacts.results
    },
    carrierInsurances() {
      this.insurances = []
      for (let i = 0; i < this.carrierInsurances.results.length; i++) {
        const insuranceRecord = this.carrierInsurances.results[i]
        this.insurances.push({
          id: insuranceRecord.id,
          createdAt: insuranceRecord.createdAt,
          updatedAt: insuranceRecord.updatedAt,
          coverageAmount: insuranceRecord.coverageAmount,
          expirationDate: insuranceRecord.expirationDate,
          provider: insuranceRecord.provider,
          producer: insuranceRecord.producer,
          policyNumber: insuranceRecord.policyNumber,
          notes: insuranceRecord.notes,
          insuranceType: insuranceRecord.insuranceType,
          insuranceTypeDisplay: insuranceRecord.insuranceTypeDisplay,
          expirationDateMenu: false,
        })
      }
    },
  },
  mounted() {
    this.loadProfileData()
  },
  methods: {
    setARLocation(value) {
      const {
        city = "",
        stateCode = "",
        countryCode = "",
        street = "",
        houseNumber = "",
        postalCode = "",
      } = value.address

      this.profile = {
        ...this.profile,
        arAddress: `${houseNumber ? houseNumber + " " : ""}${street}`,
        arCity: city,
        arState: stateCode,
        arZipcode: postalCode,
        arCountry: countryCode,
      }
    },
    setLocation(value) {
      const {
        city = "",
        stateCode = "",
        countryCode = "",
        street = "",
        houseNumber = "",
        postalCode = "",
      } = value.address

      this.profile = {
        ...this.profile,
        address: `${houseNumber ? houseNumber + " " : ""}${street}`,
        city: city,
        state: countryCode === "USA" ? stateCode : "",
        usZipcode: countryCode === "USA" ? postalCode : "",
        country: countryCode,
        caPostalCode: countryCode === "CAN" ? postalCode : "",
        caProvince: countryCode === "CAN" ? stateCode : "",
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY")
      }
    },
    loadProfileData() {
      this.$store.dispatch("carrier/GET_CARRIER_COMPANY", this.carrierId)
      const insurancePayload = `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/insurance/${this.carrierId}/`
      this.$store.dispatch("carrier/GET_CARRIER_INSURANCES", insurancePayload)
      const contactPayload = `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/contact/${this.carrierId}/`
      this.$store.dispatch("carrier/GET_CARRIER_CONTACTS", contactPayload)
    },
    addContact() {
      this.contacts.push({
        id: 0,
        createdAt: null,
        updatedAt: null,
        name: "",
        phone: "",
        email: "",
        isPrimary: false,
        sendOffer: false,
        isDispatch: false,
        isAccounting: false,
        isClaims: false,
        isDriver: false,
      })
    },
    addInsurance() {
      this.insurances.push({
        id: 0,
        createdAt: null,
        updatedAt: null,
        coverageAmount: 0,
        expirationDate: null,
        provider: "",
        producer: "",
        policyNumber: "",
        notes: "",
        insuranceType: 0,
        insuranceTypeDisplay: "",
      })
    },
    saveContact() {
      if (this.contacts.length > 0) {
        for (let i = 0; i < this.contacts.length; i++) {
          this.saveSingleContact(i)
        }
      }
    },
    saveInsurance() {
      if (this.insurances.length > 0) {
        for (let i = 0; i < this.insurances.length; i++) {
          this.saveSingleInsurance(i)
        }
      }
    },
    removeContact(value) {
      // value is index
      this.removeItem = "contact"
      this.dialog = true
      this.index = value
    },
    removeInsurance(value) {
      // value is index
      this.removeItem = "insurance"
      this.dialog = true
      this.index = value
    },
    saveSingleContact(value) {
      let contactPayload = {}
      if (this.contacts[value].id === 0) {
        const profileContact = this.contacts[value]
        contactPayload = {
          carrierId: this.profile.id,
          contact: {
            name: profileContact.name,
            phone: profileContact.phone,
            email: profileContact.email,
            is_primary: profileContact.isPrimary,
            send_offers: profileContact.sendOffers,
            is_dispatch: profileContact.isDispatch,
            is_accounting: profileContact.isAccounting,
            is_claims: profileContact.isClaims,
            is_driver: profileContact.isDriver,
          },
        }
        this.$store.dispatch("carrier/ADD_CARRIER_CONTACT", contactPayload)
      } else {
        const profileContact = this.contacts[value]
        contactPayload = {
          carrierId: this.profile.id,
          contactId: profileContact.id,
          contact: {
            name: profileContact.name,
            phone: profileContact.phone,
            email: profileContact.email,
            is_primary: profileContact.isPrimary,
            send_offers: profileContact.sendOffers,
            is_dispatch: profileContact.isDispatch,
            is_accounting: profileContact.isAccounting,
            is_claims: profileContact.isClaims,
            is_driver: profileContact.isDriver,
          },
        }
        this.$store.dispatch("carrier/UPDATE_CARRIER_CONTACT", contactPayload)
      }
    },
    saveSingleInsurance(value) {
      let insurancePayload = {}
      const profileInsurance = this.insurances[value]
      if (profileInsurance.id === 0) {
        insurancePayload = {
          carrierId: this.profile.id,
          insurance: {
            coverage_amount: profileInsurance.coverageAmount,
            expiration_date: profileInsurance.expirationDate,
            provider: profileInsurance.provider,
            producer: profileInsurance.producer,
            policy_number: profileInsurance.policyNumber,
            notes: profileInsurance.notes,
            insurance_type: profileInsurance.insuranceType,
          },
        }
        this.$store.dispatch("carrier/ADD_CARRIER_INSURANCE", insurancePayload)
      } else {
        insurancePayload = {
          carrierId: this.profile.id,
          insuranceId: profileInsurance.id,
          insurance: {
            coverage_amount: profileInsurance.coverageAmount,
            expiration_date: profileInsurance.expirationDate,
            provider: profileInsurance.provider,
            producer: profileInsurance.producer,
            policy_number: profileInsurance.policyNumber,
            notes: profileInsurance.notes,
            insurance_type: profileInsurance.insuranceType,
          },
        }
        this.$store.dispatch(
          "carrier/UPDATE_CARRIER_INSURANCE",
          insurancePayload
        )
      }
    },
    removeRecord(idx) {
      if (this.removeItem === "insurance") {
        if (this.insurances[idx].id > 0) {
          const payload = {
            insuranceId: this.insurances[idx].id,
          }
          this.$store.dispatch("carrier/DELETE_CARRIER_INSURANCE", payload)
        }
        this.insurances.splice(idx, 1)
      } else if (this.removeItem === "contact") {
        if (this.contacts[idx].id > 0) {
          const payload = {
            contactId: this.contacts[idx].id,
          }
          this.$store.dispatch("carrier/DELETE_CARRIER_CONTACT", payload)
        }
        this.contacts.splice(idx, 1)
      }
      this.dialog = false
    },
    updateProfile() {
      if (this.$refs["profile-form"].validate()) {
        let payload = {}
        if (this.profile.id) {
          payload = {
            id: this.profile.id,
            carrier: {
              blacklist: this.profile.blacklist,
              ein: this.profile.ein,
              name: this.profile.name,
              mc_number: this.profile.mcNumber,
              dot_number: this.profile.dotNumber,
              scac_number: this.profile.scacNumber,
              phone: this.profile.phone,
              phone_two: this.profile.phoneTwo,
              phone_three: this.profile.phoneThree,
              fax: this.profile.fax,
              email: this.profile.email,
              send_offers: this.profile.sendOffers,
              days_between_emails: this.profile.daysBetweenEmails,
              carrier_manager: this.profile.carrierManager?.id ?? null,
            },
          }
          this.$store.dispatch("carrier/UPDATE_CARRIER_COMPANY", payload)
        }
      }
    },
    updateARDetail() {
      if (this.$refs["carrier-ar-form"].validate()) {
        let payload = {}
        if (this.profile.id) {
          payload = {
            id: this.profile.id,
            carrier: {
              ar_name: this.profile.arName,
              ar_address: this.profile.arAddress,
              ar_city: this.profile.arCity,
              ar_state: this.profile.arState,
              ar_zipcode: this.profile.arZipcode,
              ar_country: this.profile.arCountry,
              ar_phone: this.profile.arPhone,
              ar_email: this.profile.arEmail,
              ar_remit_email: this.profile.arRemitEmail,
              ar_contact_name: this.profile.arContactName || "",
              pay_terms: this.profile.payTerms,
              quickbooks_id: this.profile.quickbooksId,
              quick_pay: this.profile.quickPay,
            },
          }
          this.$store.dispatch("carrier/UPDATE_CARRIER_COMPANY", payload)
        }
      }
    },
    updateAddressDetail() {
      if (this.$refs["carrier-address-form"].validate()) {
        let payload = {}
        if (this.profile.id) {
          payload = {
            id: this.profile.id,
            carrier: {
              address: this.profile.address,
              city: this.profile.city,
              state: this.profile.state,
              us_zipcode: this.profile.usZipcode,
              country: this.profile.country,
                ca_postal_code: this.profile.caPostalCode,
                ca_province: this.profile.caProvince,
            },
          }
          this.$store.dispatch("carrier/UPDATE_CARRIER_COMPANY", payload)
        }
      }
    },
    approveCarrier() {
      this.$store
        .dispatch("carrier/APPROVE_CARRIER", this.profile.id)
        .then(() => {
          this.$store.dispatch("carrier/GET_CARRIER_COMPANY", this.carrierId)
        })
    },
    selectedCarrierManager(value) {
      if (value.csr) {
        this.profile.carrierManager = value.csr
      }
    },
  },
}
</script>
<style scoped>
.font-size-14 {
  font-size: 14px;
}
</style>
